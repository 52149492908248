@import './_variables';
@import './_mixins';
@import './layout/utils';
@import './_vars';
@import './shared/column-filter.scss';
@import './common.scss';
@import './shared/column-selector.scss';

$mediumBorder: #e1e1e1;
$softBorder: #eeeeee;
$stripeColor: #f8f9fa;
$searchRowSpace: 30px;

.fa-sort {@include icon-override("keyboard_arrow_right");}
.fa-sort-asc {@include icon-override("keyboard_arrow_up");}
.fa-sort-desc {@include icon-override("keyboard_arrow_down");}

.pi {
    &.pi-sort {
        @include icon-override("keyboard_arrow_right");
    }
}

body {
    .p-datatable-caption {
        background-color: white;
        border-color: white;
        color: black;
    }
    // .p-datatable-header {
    //     background-color: white;
    //     border-color: white;
    //     color: black;
    // }

    .p-chkbox {
        cursor: pointer;
    }

    // .p-table.clickable,
    .p-datatable.clickable {
        .p-datatable-data > tr,
        .p-datatable-tbody > tr {
            cursor: pointer;
        }
    }

    // .p-table.shadow,
    .p-datatable.shadow {
        @extend .ui-shadow-1;
        border: none;

        // .p-table-tablewrapper,
        .p-datatable-tablewrapper {
            border-bottom: none;
        }

        // .p-table-thead > tr,
        .p-datatable-thead > tr {
            border: none;

            th {
                border: none;
            }
        }

        .p-datatable-data,
        // .p-table-tbody {
        .p-datatable-tbody {
            border: none;
        }

        .p-datatable-data > tr,
        // .p-table-tbody > tr {
        .p-datatable-tbody > tr {
            border-right: none;
            border-bottom: none;
            border-left: none;
        }
        
        .p-paginator {
            border: none;   
            font-size: 13px;  
        }
    }
    .p-datatable-resizable .p-datatable-tbody > tr > td {
        overflow: hidden;
        white-space: normal;
    }
    .p-datatable table {
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: fixed;

        .p-datatable-tablewrapper {
            border-bottom: 1px solid $dividerColor;
        }

        .p-datatable-data,
        .p-datatable-tbody {
            // tr.p-table-even.p-highlight:hover, tr.p-table-odd.p-highlight:hover,
            tr.p-datatable-even.p-highlight:hover, tr.p-datatable-odd.p-highlight:hover {
                @include hover-element();
            }

            > tr:nth-child(odd) {
                background-color: $stripeColor;

                &.p-highlight {
                  background-color: $stripeColor;
                }

                &.p-contextmenu-selected {
                  background-color: $stripeColor;
                }
            }
        }

        .p-datatable-thead > tr {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
            height: 56px;
            border: 1px solid $dividerColor;
            border-bottom-color: $mediumBorder;

            th, th.p-highlight {
                border-left-width: 0;
                border-right-width: 0;
                background-color: white;
                border-top-color: inherit;
                font-weight: bold;

                .p-sortable-column-icon {
                    padding-top: 3px;
                }
            }

            th:hover, th.p-highlight:hover {
                @include hover-element();
            }
        }

        
        .p-datatable-thead > tr,
        .p-datatable-tfoot > tr {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
            height: inherit;

            border: 1px solid $dividerColor;
            border-bottom-color: $mediumBorder;
            border-left-width: 0;
            border-right-width: 0;

            th, td, th.p-highlight {
                padding: 0.625em 1em 0.625em;
                border-left-width: 0;
                border-right-width: 0;
                background-color: white;
                border-top-color: inherit;
                font-weight: bold;
                overflow: hidden;
            }
        }

        thead {
            th {
                text-align: left;
                vertical-align: top;
            }
        }

        .p-datatable-data > tr > td,
        .p-datatable-tbody > tr > td {
            height: 50px;
            border-color: $softBorder;
            border-left-width: 0;
            border-right-width: 0;
            overflow: hidden;

        }

        .p-paginator {
            background-color: white;

            a {
                color: $textColor;
            }

            .p-paginator-pages a.p-highlight {
                background-color: #919191;
                color: white;
            }
        }

        .p-datatable-summary {
            border: 0;
            padding: 0;
        }
    }
    // .p-table,
    .p-datatable {
        margin: 12px $tableMargin 80px $tableMargin;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.87);

        // .p-table-tablewrapper,
        .p-datatable-tablewrapper {
            border-bottom: 1px solid $dividerColor;
        }

        .p-datatable-data,
        .p-datatable-tbody {
            // tr.p-table-even.p-highlight:hover, tr.p-table-odd.p-highlight:hover,
            tr.p-datatable-even.p-highlight:hover, tr.p-datatable-odd.p-highlight:hover {
                @include hover-element();
            }

            > tr:nth-child(odd) {
                background-color: $stripeColor;

                &.p-highlight {
                  background-color: $stripeColor;
                }

                &.p-contextmenu-selected {
                  background-color: $stripeColor;
                }
            }
        }

        .p-datatable-thead > tr {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
            height: 56px;
            border: 1px solid $dividerColor;
            border-bottom-color: $mediumBorder;

            th, th.p-highlight {
                border-left-width: 0;
                border-right-width: 0;
                background-color: white;
                border-top-color: inherit;
                font-weight: bold;

                .p-sortable-column-icon {
                    padding-top: 3px;
                }
            }

            th:hover, th.p-highlight:hover {
                @include hover-element();
            }
        }

        // .p-table-thead > tr,
        // .p-table-tfoot > tr {
        .p-datatable-thead > tr,
        .p-datatable-tfoot > tr {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
            height: inherit;

            border: 1px solid $dividerColor;
            border-bottom-color: $mediumBorder;

            th, td, th.p-highlight {
                padding: 0.625em 1em 0.625em;
                border-left-width: 0;
                border-right-width: 0;
                background-color: white;
                border-top-color: inherit;
                font-weight: bold;
                overflow: hidden;
            }
        }

        thead {
            th {
                text-align: left;
                vertical-align: top;
            }
        }

        .p-datatable-data > tr > td,
        // .p-table-tbody > tr > td {
        .p-datatable-tbody > tr > td {
            height: 50px;
            border-color: $softBorder;
            border-left-width: 0;
            border-right-width: 0;
            overflow: hidden;

        }

        .p-paginator {

            a {
                color: $textColor;
            }

            .p-paginator-pages a.p-highlight {
                background-color: #919191;
                color: white;
            }
        }

        .p-datatable-summary {
            border: 0;
            padding: 0;
        }
    }

    .p-paginator {
        > a {
            color: $textColor;
        }

        .pi-caret-down:before {
            color: $secondaryTextColor
        }

        .p-dropdown {
            background-image: linear-gradient(to bottom, $primaryColor, $primaryColor), linear-gradient(to bottom, $dividerColor, $dividerColor);
            min-width: 1em;
            height: auto;
            width: 35px;
            font-size: 13px;
            color: black;

            @include gt-xs {
                margin-left: 1em;
            }
        }

        @include xs {
            .p-paginator-pages {
                display: none;
            }
        }

        .p-paginator-left-content {
            flex-grow: 1.5;
            margin-right: 2em;

            @include xxs {
                display: none;
            }
        }

        .p-paginator-right-content {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            margin-left: 2em;

            @include xxs {
                flex-grow: 0;
            }
        }

        .p-paginator-element:hover {
            background-color: $hoverBgColor;
        }
    }

    .p-paginator-top {
        margin-top: -50px;
    }
    // .p-paginator.p-component-header {
    // .p-paginator {
    //     color: $textColor;
    //     border: none;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     flex-wrap: wrap;
    // }

    .custom-card-table.card-table
    .p-datatable {
        margin: 12px 0px 80px 0px;
    }

    // .card-table .p-table,
    .card-table .p-datatable {
        margin: 0 -23px -25px -23px;

        thead tr {
            border-top-color: $mediumBorder;
        }

        tbody {
            border-left-color: white;
            border-right-color: white;
        }

        tr {
            border-left-width: 0;
            border-right-width: 0;

            td, th {
                padding-left: 25px;
            }
        }
    }

     .p-datatable table{
        .p-datatable-reorder-indicator-down, .p-datatable-reorder-indicator-up {
            position: absolute;
            display: none;
        }
    }
}

.table-header {
    margin-bottom: 5px;
    margin-left: $tableMargin;
}

.mat-mdc-mini-fab.table-header-btn {
    margin-top: 20px;
    margin-right: $tableMargin;
}
.custom-circle-btn.mat-mdc-mini-fab.table-header-circle-btn {
    margin-right: 0;
}

.mat-mdc-mini-fab.table-header-circle-btn {
    margin-right: $tableMargin;
}

.searchbox {
    background-color: white;
    font-size: 14px;
    padding: 5px;
    box-sizing: border-box;
    max-height: 100%;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    justify-content: center;
    align-content: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    @extend .ui-shadow-1;

    mat-icon {
        margin: 2px 2px 0 0;
    }

    input {
        border: none;
        flex: 1 1 100%;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        font-size: 16px;
    }

    input:focus {
        outline: none;
    }
}

.search-row {
    margin: 26px $tableMargin 12px $tableMargin;

    .searchbox {
        margin-right: $searchRowSpace;
    }

    .searchbox {
        width: 356px;
        display: flex;
        flex-direction: row;
    }
}

// Styles for Cost (Other) Dropdown in table header
// body .p-table .p-table-thead > tr th ah-project-currency-select .mat-button,
body .p-datatable .p-datatable-thead > tr th ah-project-currency-select .mat-mdc-button {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: bold;
}

.asset-thumbnail-image {
    object-fit: cover;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
