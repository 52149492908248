@import "../variables";

/* Layout */
$bodyBgColor:#f7f7f7;
$maskBgColor:#424242;
$formGroupPadding:1em;

/* Topbar */
$topbarButtonColor:#ffffff;
$topbarButtonHoverColor:#e8e8e8;
$topbarSearchBorderColor:#ffffff;
$topbarSearchColor:#ffffff;
$topbarIconColor:#ffffff;
$topbarIconHoverColor:#e8e8e8;
$topbarMenuBgColor:#ffffff;
$topbarSubmenuBgColor:#ffffff;

/* Menus */
$darkMenuColor:#ffffff;
$layoutMenuBgColor:#ffffff;
$layoutMenuScrollbarBgColor:#aaaaaa;
$layoutMenuItemIconColor:#757575;
$layoutMenuItemActiveColor:#e8e8e8;
$horizontalLayoutMenuItemTextColor:#ffffff;
$horizontalLayoutMenuItemHoverBgColor:#e8e8e8;
$horizontalLayoutMenuSubMenuBgColor:#ffffff;
$horizontalLayoutMenuSubMenuActiveBgColor:#e8e8e8;
$horizontalDarkLayoutMenuItemTextColor:#ffffff;
$slimLayoutMenuSubMenuBgColor:#ffffff;
$slimMenuTooltipColor:#ffffff;
$rightPanelBgColor:#ffffff;
$profileMenuBorderBottomColor:#d6d5d5;
$profileMenuDarkBorderBottomColor:#545454;
