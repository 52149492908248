body {
    .p-component,
    .p-component .p-component {
        font-family: "Roboto","Helvetica Neue",sans-serif;
        text-decoration: none;
    }

    .p-component-content {
        background-color: $contentBgColor;
        font-size: $contentFontSize;
        border: 1px solid $dividerColor;

        .pi {
            color: $contentIconColor;
        }
    }

    // .p-component-header {
    .p-fileupload .p-fileupload-buttonbar {
        background: $headerBgColor;
        color: $headerTextColor;
        font-size: $headerFontSize;
        border: 1px solid $primaryColor;

        .pi {
            color: $headerIconColor;
        }
    }

    .p-highlight {
        //Keep the commented line due to fix if any page is broken
        // background-color: $accentColor;
        // color: $accentTextColor;

        .pi {
            color: $accentTextColor;
        }
    }

    .p-component:disabled,
    .p-disabled {
        opacity: .35;
        filter: Alpha(Opacity=35);
        background-image: none;
        cursor: auto !important;
    }

    .p-corner-all {
        @include border-radius($borderRadius);
    }

    .p-corner-top {
        @include border-radius-top($borderRadius);
    }

    .p-corner-bottom {
        @include border-radius-bottom($borderRadius);
    }

    .p-corner-left {
        @include border-radius-left($borderRadius);
    }

    .p-corner-right {
        @include border-radius-right($borderRadius);
    }

    .p-component-overlay {
        background-color: $overlayBgColor;
        opacity: .8;
    }
}
