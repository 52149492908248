@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$ah-primary: mat.define-palette(mat.$blue-grey-palette);
$ah-accent:  mat.define-palette(mat.$deep-orange-palette);

// The warn palette is optional (defaults to red).
$ah-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ah-theme: mat.define-light-theme($ah-primary, $ah-accent, $ah-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-component-themes($ah-theme);

// PrimeNG Theme variables
$primaryColor: map-get($ah-primary, default);
$primaryDarkColor: map-get($ah-primary, darker);
$primaryLightColor: map-get($ah-primary, 50);//map-get($ah-primary, lighter);
$accentColor: map-get($ah-accent, default);
$accentDarkColor: map-get($ah-accent, darker);
$accentLightColor: map-get($ah-accent, lighter);
$darkMenuRouterLinkActiveColor: $accentColor;
$lightMenuRouterLinkActiveColor: $accentColor;
$horizontalLightMenuRouterLinkActiveColor: $accentColor;

@import './_vars';
@import './theme/_theme';
// @import '../../assets/sass/layout/_layout';
// @import '../../assets/sass/_fonts';
@import './layout/_exception';
@import './layout/utils';
@import './layout/_main';
