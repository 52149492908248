body {
    /* Messages */
    .p-messages {
        padding: $headerPadding;

        ul {
            display: inline-block;
            margin-left: 0;
        }

        &.p-messages-info {
            background-color: $infoMessageBgColor;
            border-color: $infoMessageBorderColor;
            color: $infoMessageTextColor;
        }

        &.p-messages-warn {
            background-color: $warnMessageBgColor;
            border-color: $warnMessageBorderColor;
            color: $warnMessageTextColor;
        }

        &.p-messages-error {
            background-color: $errorMessageBgColor;
            border-color: $errorMessageBorderColor;
            color: $errorMessageTextColor;
        }

        &.p-messages-success {
            background-color: $successMessageBgColor;
            border-color: $successMessageBorderColor;
            color: $successMessageTextColor;
        }

        .p-messages-close {
            text-decoration: none;
            color: $messageIconColor;
            right: .25em;
        }
    }

    .p-messages .p-messages-icon {
        background: none;
        color: $messageIconColor;
        margin-top: -2px;

        &.pi-info-circle {
            @include material-icon("info");
            font-size: 1.75em;
        }

        &.pi-exclamation-triangle {
            @include material-icon("warning");
            font-size: 1.75em;
        }

        &.pi-times {
            @include material-icon("error_outline");
            font-size: 1.75em;
        }

        &.pi-check {
            @include material-icon("check_circle");
            font-size: 1.75em;
        }
    }

    .p-message {

         &.p-message-info {
             background-color: $infoMessageBgColor;
             border-color: $infoMessageBorderColor;
             color: $infoMessageTextColor;
         }

         &.p-message-warn {
             background-color: $warnMessageBgColor;
             border-color: $warnMessageBorderColor;
             color: $warnMessageTextColor;
         }

         &.p-message-error {
             background-color: $errorMessageBgColor;
             border-color: $errorMessageBorderColor;
             color: $errorMessageTextColor;
         }

         &.p-message-success {
             background-color: $successMessageBgColor;
             border-color: $successMessageBorderColor;
             color: $successMessageTextColor;
         }

         .p-message-close {
             text-decoration: none;
             color: $messageIconColor;
             right: .25em;
         }
    }

    .p-message .p-message-icon {
        background: none;
        color: $messageIconColor;
        margin-top: -2px;

        &.pi-info-circle {
            @include material-icon("info");
            font-size: 1.75em;
        }

        &.pi-exclamation-triangle {
            @include material-icon("warning");
            font-size: 1.75em;
        }

        &.pi-times {
            @include material-icon("error_outline");
            font-size: 1.75em;
        }

        &.pi-check {
            @include material-icon("check_circle");
            font-size: 1.75em;
        }
    }

    .p-growl {
        top: 90px;

        > .p-growl-item-container {
            opacity: 1;

            &.p-growl-message-info {
                background-color: $infoMessageBgColor;
            }

            &.p-growl-message-warn {
                background-color: $warnMessageBgColor;
            }

            &.p-growl-message-error {
                background-color: $errorMessageBgColor;
            }

            &.p-growl-message-success {
                background-color: $successMessageBgColor;
            }

            &.ui-shadow {
                @include overlay-content-shadow();
            }
        }

        .p-growl-item {
            .p-growl-image {
                background: none;
                color: $messageIconColor;
                font-size: 1.75em;

                &.pi-info-circle {
                    @include material-icon("info");
                    font-size: 1.75em;
                }

                &.pi-exclamation-triangle {
                    @include material-icon("warning");
                    font-size: 1.75em;
                }

                &.pi-times {
                    @include material-icon("error_outline");
                    font-size: 1.75em;
                }

                &.pi-check {
                    @include material-icon("check_circle");
                    font-size: 1.75em;
                }
            }

            .p-growl-message {
                color: $messageIconColor;
            }

            .p-growl-icon-close {
                @include material-icon("close");
                font-size: 24px;
                color: $messageIconColor;
            }
        }
    }

    .p-toast {
        .p-toast-message {
            @include overlay-content-shadow();

            &.p-toast-message-info {
                background-color: $infoMessageBgColor;
                border: 0 none;
                color: $infoMessageTextColor;

                .p-toast-close-icon {
                    color: $infoMessageTextColor;
                }
            }

            &.p-toast-message-success {
                background-color: $successMessageBgColor;
                border: 0 none;
                color: $successMessageTextColor;

                .p-toast-close-icon {
                    color: $successMessageTextColor;
                }
            }

            &.p-toast-message-warn {
                background-color: $warnMessageBgColor;
                border: 0 none;
                color: $warnMessageTextColor;

                .p-toast-close-icon {
                    color: $warnMessageTextColor;
                }
            }

            &.p-toast-message-error {
                background-color: $errorMessageBgColor;
                border: 0 none;
                color: $errorMessageTextColor;

                .p-toast-close-icon {
                    color: $errorMessageTextColor;
                }
            }

            .p-toast-message-content {

                &.pi-info-circle {
                    @include material-icon("info");
                    font-size: 1.75em;
                }

                &.pi-exclamation-triangle {
                    @include material-icon("warning");
                    font-size: 1.75em;
                }

                &.pi-times {
                    @include material-icon("error_outline");
                    font-size: 1.75em;
                }

                &.pi-check {
                    @include material-icon("check_circle");
                    font-size: 1.75em;
                }
            }
        }
    }
}
