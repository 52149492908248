mat-form-field mat-error {
    font-size: 11px;
    letter-spacing: normal;
    line-height: 1.125;
}

// This matches the margin and orientation of mat-form-field perfectly
.input-wrapper-multiselect {
    margin: 15px 0 11px 0;
}

.input-wrapper-p-dropdown {
    margin: 10px 0 17px 0;
}

.p-dropdown-items-wrapper {
    margin: 10px 0 17px 0;
}

.input-wrapper-p-calendar {
    margin: 10px 0 20px 0;
}

body .p-datatable .p-datatable-data tr.p-highlight .p-inputtext.calendar-black-text {
    color: #212121;
    border-color: #bdbdbd;
}
// body .p-datatable .p-datatable-data tr.p-highlight .p-inputtext.ui-corner-all{
//      color: #212121;
//  }

.small-checkbox {
    font-size: 12px;
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
        margin: auto 5px auto auto;
        height: 15px;
        width: 15px;
    }
}

// Fixes bug where width: 100% was making filter wider than dropdown options
// when appended to body
body .p-dropdown-panel .p-dropdown-filter-container {
    width: auto;
}

// Make all p-dropdowns gray when null value selected
.blank-option .p-dropdown:not(.mat-mdc-raised-button) label {
    color: rgba(0, 0, 0, 0.38);
}

body .p-dropdown .p-dropdown-label.p-inputtext {
    padding: 0.475em 2em 0.475em 0;
    text-overflow: ellipsis;
}

#form-right-column {
    @media(min-width: 600px) {
        margin-left: 2.5em;
    }
}

body {
    .p-dropdown {
        div.p-dropdown-trigger {
            height: 100%;
            line-height: 2em;
            width: 2em;
            text-align: center;
            top: 0;
        }

        span.p-dropdown-trigger-icon {
            top: 50%;
            left: 95%;
            margin-top: -.5em;
            margin-left: -.5em;
            position: absolute;
        }
    }

    .p-multiselect {
        div.p-multiselect-trigger {
            height: 100%;
            line-height: 2em;
            width: 2em;
            text-align: center;
            top: 0;
        }
    }
}

p-multiselect {
    .multiselect-padding-full-width {
        padding: 0.4375em 0;
        width: 100%;
    }
}
