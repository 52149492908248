@import './_mixins';
@import './_vars';
@import './_mixins';
@import './layout/utils';

@mixin xxs {
    @media (max-width: 411px) {
        @content;
    }
}

@mixin xs {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin gt-xs {
    @media (min-width: 600px) {
        @content;
    }
}

.ah-col-button {
    width: 70px;
    text-align: left;
}

html, body {
    height: 100%;
    background-color: $primaryLightColor;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card.padded {
    margin: 20px 20px 60px 20px;
}

.mat-icon.svg20 {
    height: 20px;
    width: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
    cursor: pointer;
}

// page headers
h1 {
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin: 26px 33px 26px $topHeaderMargin;
}

.subheading {
    margin-left: $topHeaderMargin;
}

.clickable {
    cursor: pointer;
}

.bold-text {
    font-weight: bold;
}

.centered {
    display: block;
    text-align: center;
}

.sidenav-toggle {
    position: fixed;
    background-color: white;
    border-radius: 50%;
    padding: 1px 0 0 1px;
    margin: 5px;
    z-index: 4;
    box-shadow: 1px 1px 5px grey;
}

.top-right-mini-fab {
    position: absolute;
    top: 16px;
    right: 16px;

    // Move icons in circle buttons down 1 pixel
    mat-icon {
        margin-top: 1px;
    }
}

.mdc-button { 
    letter-spacing: normal;
 }
 
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  transition-property: all;
  @extend .ui-shadow-1;
  outline: none;
}

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  @extend .ui-shadow-2;
  outline: none;
}

.mat-mdc-raised-button.import-btn:not(:disabled) {
    @include orange-button();
    margin-right: $tableMargin;
}

.top-right-mini-fab.card-table-circle-button {
    top: 12px;
}

.mat-raised-button {
    border-radius: 2px !important;
    white-space: nowrap;
}

.mat-raised-button.p-multiselect {
    border-radius: 2px;
}

.mat-raised-button.p-dropdown {
    border-radius: 2px;
    border-bottom: none;
    width: 100%;

    .p-dropdown-label.p-inputtext {
        line-height: 36px;
        padding-right: 2px;
    }

    .p-dropdown-trigger {
        display: none;
    }
}

.mat-raised-button.p-dropdown.p-focus {
    border-bottom: none;
}

.mat-raised-button.p-dropdown {
    .p-dropdown-label.p-focus {
        padding-bottom: 1px;
    }
}

.alert {
    background-color: $accentColor;
    padding: 20px;

    > span {
        color: white;
        line-height: 36px;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-content .alert {
    margin: 0 -24px 25px -24px;
}

.scrollable-modal .mat-mdc-dialog-container {
  height: auto;
}

.hide {
    display: none;
}

.wk-flex-fix {
    flex-basis: 0 !important;
}

.p-multiselect-panel {
    .p-highlight {
        background-color: transparent !important;
    }

    // .p-chkbox.p-component .p-chkbox-box.ui-state-active {
    // .p-chkbox.p-component .p-chkbox-box.p-highlight {
    //     background-color: $accentColor;
    //     border-color: $accentColor;
    //     > .pi {
    //         color: white;
    //     }
    // }
    .p-checkbox.p-component .p-checkbox-box.p-highlight {
        background-color: $accentColor;
        border-color: $accentColor;
        checkicon {
            color: white;
            background: $accentColor;
        }
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        background-color: $accentColor;
        border-color: $accentColor;
        > .pi {
            color: white;
        }
    }
    .p-checkbox .p-checkbox-box.p-highlight:hover {
        background-color: $accentColor;
        border-color: $accentColor;
        > .pi {
            color: white;
        }
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background-color: $accentColor;
        border-color: $accentColor;
        > .pi {
            color: white;
        }
    }
}

// Card Titles with Action Icons
.action-title {
    margin: 0 0 5px 0;

    .mat-mdc-card-title {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: unset;
        display: inline-block;
    }

    .edit-btn {
        float: right;
    }

}

@include xs {
    body {
        div.p-dropdown-panel.add-customer-modal-dropdown-panel {
            max-width: 90%;
            left: 0 !important;
        }

        div.add-customer-modal-dropdown-width {
            max-width: 250px;
        }
    }
}

// Hide p-calendar overlay until they fix automatically hiding it when the calendar closes in touchUI mode
body .p-component-overlay {
    display: inline-block;
}

// Titles for modals should be centered and bolded
.mat-mdc-dialog-title {
    text-align: center;
    font-weight: 500 !important; // Need to override modal's title font-weight
}

.p-dropdown-label > .required,
.p-dropdown-item:not(.p-highlight) > .required {
    color: #f44336;
}

.p-dropdown-item.p-highlight > .required {
    color: inherit;
}

.p-dropdown-label > .default,
.p-dropdown-item:not(.p-highlight) > .default {
    color: $secondaryTextColor;
}

.p-dropdown-item.p-highlight > .default {
    color: inherit;
}

//SIDENAV ICONS SVG
.p-icon-movie {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M18 4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4h-4z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

.p-icon-tv {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z'/%3E%3C/svg%3E");
}

.p-icon-business {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z'/%3E%3C/svg%3E");
}

.p-icon-flag {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z'/%3E%3C/svg%3E");
}

.p-icon-flag-accent {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23ff5722'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z'/%3E%3C/svg%3E");
}

.p-icon-people {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z'/%3E%3C/svg%3E");
}

.p-icon-settings {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='white' viewBox='0 0 20 20'%3E%3Cpath fill='none' d='M0 0h20v20H0V0z'/%3E%3Cpath d='M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z'/%3E%3C/svg%3E");
}

.p-icon-extension {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z'/%3E%3C/svg%3E");
}

.p-icon-today {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3C/svg%3E");
}

.p-icon-person-add {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'/%3E%3C/svg%3E");
}

.p-icon-pin-drop {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M18 8c0-3.31-2.69-6-6-6S6 4.69 6 8c0 4.5 6 11 6 11s6-6.5 6-11zm-8 0c0-1.1.9-2 2-2s2 .9 2 2-.89 2-2 2c-1.1 0-2-.9-2-2zM5 20v2h14v-2H5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

.p-icon-pin-drop {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M18 8c0-3.31-2.69-6-6-6S6 4.69 6 8c0 4.5 6 11 6 11s6-6.5 6-11zm-8 0c0-1.1.9-2 2-2s2 .9 2 2-.89 2-2 2c-1.1 0-2-.9-2-2zM5 20v2h14v-2H5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

.p-icon-label {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84L22 12l-4.37-6.16z'/%3E%3C/svg%3E");
}

.p-icon-keyboard-arrow-right {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E");
}

.p-icon-keyboard-arrow-right-accent {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23ff5722'%3E%3Cpath d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E");
}

.p-icon-move-to-inbox {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M19 3H4.99c-1.11 0-1.98.9-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10zm-3-5h-2V7h-4v3H8l4 4 4-4z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E");
}

.p-icon-move-to-inbox-accent {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23ff5722' viewBox='0 0 24 24'%3E%3Cpath d='M19 3H4.99c-1.11 0-1.98.9-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10zm-3-5h-2V7h-4v3H8l4 4 4-4z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E");
}

.p-icon-search {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}

//Material Calender
 .mat-calendar-header {
    background-color: $primaryColor;
    color: white;
}
.mat-calendar-table-header {
    color: $primaryTextColor;
}
.mat-calendar-body-selected {
    background-color: $accentColor;
    color: white;
}
.mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
    color: white;
}
.mat-calendar-previous-button.mat-mdc-icon-button:hover, .mat-calendar-next-button.mat-icon-button:hover, .mat-calendar-period-button.mat-mdc-button:hover {
    background-color: $primaryHoverColor;
}
.mat-calendar-arrow {
    border-top-color: white;
}
.mat-calendar-body-cell-content:hover {
    background-color: $plainHoverColor !important;
}
.mat-calendar-body-selected:hover {
    background-color: $accentHoverColor !important;
}
//To adjust the Material Calender width and height for Touch devices
@media (orientation: portrait) {
    .mat-datepicker-content-touch .mat-calendar {
        width: unset !important;
         height: unset !important;
    }
}
@media  (orientation: landscape){
    .mat-datepicker-content-touch .mat-calendar {
        width: unset !important;
         height: unset !important;
    }
}
.mat-datepicker-content-touch {
    max-height: 90vh !important;
    overflow: unset !important;
}

p-messages {
    .saving-message {
        padding: 0.625rem 1.5rem 0.625rem 1rem;
        margin: 0;
    }
}

// Sidenav component styles
.ui-panelmenu {
    // .ui-corner-top {
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    // }

    // .ui-corner-all {
    //     border-radius: 0;
    // }

    .p-panelmenu-panel {
        margin-bottom: 35%;
    }

    .p-panelmenu-icon {
        float: right;
    }

    .p-panelmenu-content {
        border: none;
    }

    .p-icon-keyboard-arrow-right, .p-icon-keyboard-arrow-right-accent {
        float: right;
    }

    .p-panelmenu-header-link {
        height: 46px;
        display: flex;
        min-height: 2.750em;

        .p-menuitem-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 107px;
            float: left;
        }
    }

    &.dark {
        .p-panelmenu-header {
            background-color: #ffffff;

            a {
                color: $primaryTextColor;
            }

            .fa {
                color: $primaryTextColor;
            }

            .pi {
                color: $primaryTextColor;
            }

            // &.ui-state-active {
            &.p-highlight {
                background-color: #ffffff;
                a, .fa {
                    color: $primaryTextColor;
                }
            }
        }

        .p-panelmenu-content {
            background-color: $primaryDarkColor;
            .p-menuitem {
                .p-menuitem-link {
                    color: #ffffff;

                    // &.ui-state-active, &:hover {
                    &.p-highlight, &:hover {
                        background-color: rgba($primaryColor, 0.5);
                    }

                    &:hover {
                        color: #ffffff;
                    }

                    .fa {
                        color: #ffffff;
                    }

                    .pi {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

@keyframes ui-progress-spinner-color {
    100%, 0%, 40%, 66%, 80%, 90% {
        stroke: $accentColor;
    }
}
