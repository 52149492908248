$sidenav-filter-width: 200px;

$successColor: #4f744b;
$infoColor: #2196F3;

$accentTextColor: #212121;
$darkMenuBgColor: #424242;
$darkMenuHoverColor: #676767;

$primaryTextColor: #212121;
$secondaryTextColor: #757575;
$dividerColor: #BDBDBD;

// for hover rows
$grey200: #EEEEEE;
$hoverBgColor: #e8e8e8;
$plainHoverColor: rgba(199, 199, 199, 0.5);
// for selected rows
$grey100: #F5F5F5;

// Anthony Theme variables
$blueButtonColor: #607D8B;
$orangeButtonColor: #FF5722;
$whiteButtonColor: white;

$topHeaderMargin: 50px;
$tableMargin: 40px;

$primaryColor: #607d8b;
$primaryHoverColor: rgba(121, 154, 170, 0.5);
$primaryDarkColor: #455a64;
$primaryLightColor: #eceff1;
$accentColor: #ff5722;
$accentHoverColor: #fc3b00;
$accentDarkColor: #e64a19;
$accentLightColor: #ffccbc;

$greyBackground: #F3F3F3;

$epBlueDark: #10457a;
$epGreen: #78b75e;
$epBlueLight: #06b6ed;