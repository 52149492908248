@import '_mixins';

mat-dialog-actions > button.mat-mdc-raised-button:last-child:not(:disabled) {
    @include blue-button();
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-content.mat-mdc-dialog-content {
    padding: 24px;
    max-height: 100%;
}

.delete-button {
    @include orange-button();
}

.mdc-dialog .mdc-dialog__content {
    margin: 0 -24px;
}

span.mat-mdc-dialog-title.mdc-dialog__title {
    font-size: 34px;
    line-height: 52px;
}

div.mat-mdc-dialog-title.mdc-dialog__title {
    font-size: 34px;
    line-height: 52px
}

.mat-mdc-dialog-container .mdc-dialog__title {
    padding: 0;
}
.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        padding: 24px 24px 15px 24px;
    }
    max-height: 95vh !important;
    @media(max-width: 959px) {
        max-height: 90vh !important;
    }
}

mat-form-field.mat-mdc-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: unset;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: white;
    }

    .mdc-text-field {
        padding: 0px;
    }
    @media(min-width: 600px) and (max-width: 959px) {
        display: block;
    }
}

// Use with Angular Material Dialog's panelClass config option
.dialog-container-width-centered {
    .mat-mdc-dialog-container {
        width: 80%;
        margin: 0 auto;
    }
}

::-webkit-scrollbar-corner {
    background: $greyBackground;
    border-radius: 10px;
}

.static-width {
    .mat-dialog-container {
        width: 65vw;
    }
}
