// Choose Columns css - imported to global styles in tables.scss (necessary to style child elements of PrimeNG components)
.column-filter .p-multiselect {
    z-index: 99;
    border: none;
    height: 36px;
    padding: 0 16px;

    .p-multiselect-panel {
        right: 0;
        left: auto !important;
        margin-top:20px;
    }

    .p-multiselect-label-container {
        padding-right: 0;
    }

    .p-multiselect-label {
        display: none;
    }
}

.mat-raised-button:not([class*=mat-elevation-z]) {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.transaction-column-filter .p-multiselect {
    z-index: 99;
    border-bottom: none;
    height: 35px;

    .p-multiselect-panel {
        right: 0;
        left: auto !important;
        margin-top:20px;
    }

    .p-multiselect-label-container {
        padding-right: 0;
    }

    .p-multiselect-label {
        display: none;
    }
}
