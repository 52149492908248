@import './layout/_utils';
@import './_vars';

.p-tabmenu.p-component {
    font-size: 14px;
    // color: white;
    @extend .ui-shadow-2;
    // .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    //     background: red;
    //     border-color: rgba(0,0,0,.12);
    //     color: #3f51b5;
    // }
    .p-tabmenu-nav {
        background-color: $primaryColor;
        padding-left: $tableMargin;

        > .p-tabmenuitem {
            > a {
                height: 48px;
                padding: 1em 1em;
                .p-menuitem-text {
                    margin-right: 3px;
                    color: white;
                    text-shadow: 1px 0 #853713;
                }

            }
            border-color: $primaryColor;
           
            &:not(.p-highlight):hover {
                background-color: $primaryColor;
                color: white;
            }
            &:not(.p-highlight) {
                background-color: $primaryColor;
                color: white;
                > .p-menuitem-link {
                        background: $primaryColor;
                        border-color: $primaryColor;
                        color: white
                    }
            }
            &.p-highlight:hover {
                background-color: $primaryColor;
            }

            &.p-highlight {
                border-color: $accentColor;
                background-color: $primaryColor;
                color: white;
                > .p-menuitem-link {
                        background: $primaryColor;
                        color: white
                    }
            }
        }
        .p-tabmenu-ink-bar {
            display: block;
            position: absolute;
            bottom: 0;
            height: 2px;
            background-color: $accentColor;
            transition: .5s cubic-bezier(.35,0,.25,1);
        }
    }
}

.p-tieredmenu {
    width: 150px;

    .p-submenu-list {
        width: 150px;
    }
}
