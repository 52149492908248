body {
    .p-inputtext {
        background: $inputBgColor no-repeat;
        background-image: linear-gradient(to bottom, $primaryColor, $primaryColor), linear-gradient(to bottom, $inputBorderColor, $inputBorderColor);
        background-size: 0 2px, 100% 1px;
        background-position: 50% 100%, 50% 100%;
        transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
        border-width: 0;
        padding: 2px;
        font-size: $inputFontSize;
        @include border-radius(0px);

        &:focus,
        &.p-focus {
            border-width: 0;
            background-size: 100% 2px, 100% 1px;
            outline: none;
            padding-bottom: 2px;
        }

        &:disabled {
            border-bottom: 1px dotted;
        }

        // &.p-widget-content {
        //     border-width: 1px;
        //     background: $inputBgColor;
        //     background-image: none;
        // }

        &.p-state-error {
            border-color: $inputBorderErrorColor;
        }

    }

    .p-inputtext:-webkit-autofill {
        border-color: $inputAutoFillBorderColor;
        border-style: solid;
        border-width: 0px 0px 1px 0px;

        &.p-focus {
            padding-bottom: 0px;
        }
    }

    .p-widget-header:not(.p-dataview-header) {
        .p-inputtext {
            background-image: linear-gradient(to bottom, $headerTextColor, $headerTextColor), linear-gradient(to bottom, lighten($primaryColor,25%),  lighten($primaryColor,25%));
        }
    }

    .md-inputfield {
        display: block;
        position:relative;

        > label {
          color:$inputFieldLabelTextColor;
          font-weight:normal;
          position:absolute;
          pointer-events:none;
          left:5px;
          top:1px;
          transition: 0.3s ease all;
          -moz-transition: 0.3s ease all;
          -webkit-transition: 0.3s ease all;
        }

        input:focus ~ label,
        input.p-state-filled ~ label,
        .p-inputwrapper-focus ~ label,
        .p-inputwrapper-filled ~ label {
          top:-20px;
          font-size:12px;
          color:$primaryColor;
        }

        .input:-webkit-autofill ~ label {
          top:-20px;
          font-size:12px;
          color:$primaryColor;
        }

        input.ng-dirty.ng-invalid ~ label {
            color: $inputErrorTextColor;
        }

        .p-message {
            position: absolute;

            &.p-messages-error {
                background-color: $inputBgColor;
                border: 0 none;
                margin: 0px;
                color: $inputErrorTextColor;
                font-size: .75em;
            }
        }

        &.md-inputfield-fill {
            input {
              background-color: $inputFieldFillBgColor;
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 4px;
            }

            label {
              top: 2px;
            }
            input:focus ~ label,
            input.p-state-filled ~ label,
            .p-inputwrapper-focus ~ label,
            .p-inputwrapper-filled ~ label {
              top:-20px;
              font-size:12px;
              color:$primaryColor;
            }
        }

        &.md-inputfield-box {
            background-color: $inputFieldBoxBgColor;
            height: 44px;

            >*:not(label) {
                bottom: 0px;
                position: absolute;
                background-color: $inputFieldBoxBgColor;
                padding-left: 4px;
                padding-right: 4px;
                padding-top: 24px;
            }

            >label {
                top: 23px;
            }

            input:focus ~ label,
            input.p-state-filled ~ label,
            .p-inputwrapper-focus ~ label,
            .p-inputwrapper-filled ~ label {
                top:1px;
            }

            .input:-webkit-autofill ~ label {
                top:1px;
            }
        }
    }

    .p-material-message {
        &.p-message {
            &.p-messages-error {
                background-color: $inputBgColor;
                color: $inputErrorTextColor;
                border: 0 none;
                margin: 0px;
                font-size: .75em;

                label {
                    top: 4px;
                }
            }
        }
    }

    .p-listbox {
        min-width: 12.5em;
        background-color: $inputListItemBgColor;
        @include border-radius($borderRadius);

        &.p-inputtext {
            padding: 0;
        }

        .p-listbox-list {
            padding: 0;
        }

        .p-listbox-item {
            overflow: hidden;
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            margin: 0;
            color: $textColor;
            @include rippleitem();
            @include transition(background-color .3s);
            @include border-radius(0);

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }

        &:not(.p-disabled) {
            .p-listbox-item {
                &:not(.p-highlight):hover {
                    @include hover-element();
                }
            }
        }

        .p-listbox-header {
            @include border-radius(0);
            padding: $inputHeaderPadding;

            .p-chkbox {
                .p-chkbox-box {
                    border-color: $inputListItemCheckboxBorderColor;
                }
            }

            .p-listbox-filter-container {
                .p-inputtext {
                    padding: 2px;
                    padding-left: 1.5em;
                    color: $inputListItemFilterTextColor;
                }

                .pi {
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .p-button, .p-datepicker-buttonpane > button {
        overflow: hidden;
        background-color: $primaryColor;
        color: $buttonTextColor;
        font-size: $buttonFontSize;
        height: 2.25em;
        padding: 0 1em;
        border: 0 none;
        -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    	box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        @include transition(background-color .3s);
        vertical-align: middle;

        &:enabled:not(:focus):hover {
            background-color: $primaryDarkColor;
        }
        &:enabled:focus {
            background-color: $primaryDarkColor;
        }

        &:focus {
            outline: 0 none;
            background-color: lighten($primaryColor,10%);
        }

        .p-button-text {
            padding: 0;
            line-height: 2.25em;
        }

        .pi {
            color: $buttonTextColor;
        }

        &.p-button-icon-only {
            @include border-radius(50%);
            width: 2.5em;
            height: 2.5em;

            // .pi {
            //     margin-left: -.5em;
            // }
        }

        &.p-button-text-icon-left {
            padding-left: 2.5em;
        }

        &.p-button-text-icon-right {
            padding-right: 2.5em;
        }

        &.secondary, &.p-secondary-button {
            background-color: $accentColor;
            color: $accentTextColor;

            &:enabled:not(:focus):hover {
                background-color: $accentDarkColor;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($accentColor,10%);
            }
        }

        &.blue-grey-btn {
            background-color: $blueGrey;

           &:enabled:not(:focus):hover {
                background-color: $blueGreyHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($blueGrey,10%);
            }
        }

        &.cyan-btn {
            background-color: $cyan;

            &:enabled:not(:focus):hover {
                background-color: $cyanHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($cyan,4%);
            }
        }

        &.teal-btn {
            background-color: $teal;

            &:enabled:not(:focus):hover {
                background-color: $tealHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($teal,10%);
            }
        }

        &.red-btn, &.p-danger-button {
            background-color: $red;

            &:enabled:not(:focus):hover {
                background-color: $redHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($red,10%);
            }
        }

        &.green-btn, &.p-success-button {
            background-color: $green;

           &:enabled:not(:focus):hover {
                background-color: $greenHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($green,10%);
            }
        }

        &.deep-orange-btn {
            background-color: $deepOrange;

            &:enabled:not(:focus):hover {
                background-color: $deepOrangeHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($deepOrange,10%);
            }
        }

        &.purple-btn {
            background-color: $purple;

            &:enabled:not(:focus):hover {
                background-color: $purpleHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($purple,10%);
            }
        }

        &.pink-btn {
            background-color: $pink;

            &:enabled:not(:focus):hover {
                background-color: $pinkHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($pink,10%);
            }
        }

        &.amber-btn, &.p-warning-button {
            background-color: $amber;
            color: $textColor;

            &:enabled:not(:focus):hover {
                background-color: $amberHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($amber,10%);
            }
        }

        &.orange-btn {
            background-color: $orange;

            &:enabled:not(:focus):hover {
                background-color: $orangeHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($orange,10%);
            }
        }

        &.brown-btn {
            background-color: $brown;

            &:enabled:not(:focus):hover {
                background-color: $brownHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($brown,10%);
            }
        }

        &.indigo-btn {
            background-color: $indigo;

            &:enabled:not(:focus):hover {
                background-color: $indigoHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($indigo,10%);
            }
        }

        &.blue-btn, &.p-info-button {
            background-color: $blue;

            &:enabled:not(:focus):hover {
                background-color: $blueHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($blue,10%);
            }
        }

        &.flat {
            @include no-shadow();
        }
    }

    // .p-button.p-state-default.p-button-secondary, .p-buttonset.p-button-secondary > .p-button.p-state-default  {
    .p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button  {
        color: $textColor;
        background-color: $secondary;

        .p-button-icon-left {
            color: $textSecondaryColor;
        }

        .p-button-icon-right {
            color: $textSecondaryColor;
        }

        &:enabled:hover {
            @include hover-element();
        }

        &:enabled:focus {
            background-color: darken($secondary, 10%);
        }
    }

    // .p-button.p-state-default.p-button-info, .p-buttonset.p-button-info > .p-button.p-state-default  {
    .p-button.p-button-info, .p-buttonset.p-button-info > .p-button {
        background-color: $blue;

        &:enabled:hover {
            background-color: $blueHover;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten($blue,10%);
        }
    }

    // .p-button.p-state-default.p-button-success, .p-buttonset.p-button-success > .p-button.p-state-default  {
    .p-button.p-button-success, .p-buttonset.p-button-success > .p-button  {
        background-color: $green;

        &:enabled:hover {
            background-color: $greenHover;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten($green,10%);
        }
    }

    // .p-button.p-state-default.p-button-warning, .p-buttonset.p-button-warning > .p-button.p-state-default  {
    .p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button  {
        color: $textColor;
        background-color: $amber;

        &:enabled:hover {
            background-color: $amberHover;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten($amber,10%);
        }
    }

    // .p-button.p-state-default.p-button-danger, .p-buttonset.p-button-danger > .p-button.p-state-default  {
    .p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button  {
        background-color: $red;

        &:enabled:hover {
            background-color: $redHover;
        }

        &:enabled:focus {
            outline: 0 none;
            background-color: lighten($red,10%);
        }
    }

    .p-buttonset:not(.p-button-danger,p-button-secondary,p-button-success,p-button-info,p-button-warning) {
        .p-button {
            &:not(.p-disabled):not(.p-state-active):hover {
                background-color: $primaryDarkColor;
            }

            &.p-state-active {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }


        &.p-button-danger {
            &:enabled:not(:focus):hover {
                background-color: $redHover;
            }

            &:focus {
                outline: 0 none;
                background-color: lighten($red,10%);
            }
        }
    }

    .p-togglebutton {
        &:not(.p-state-active):not(.p-disabled):hover {
            background-color: $primaryDarkColor;
        }

        &.p-state-active {
            background-color: $accentColor;
            color: $accentTextColor;
        }
    }

    .p-splitbutton {
        @include border-radius($borderRadius + 1);
        -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    	box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);

        > .p-button {
            @include no-shadow();

            &:active {
                background-color: lighten($primaryColor,10%);
            }
        }

        .p-splitbutton-menubutton {
            height: 2.25em;
            @include border-radius-left(0);
            @include border-radius-right($borderRadius);
        }

        .p-menu {
            width: 100%;
            min-width: 12.5em;
        }
    }

    .p-fluid {
        .p-splitbutton {
            .p-button:first-child {
                width: calc(100% - 2em);
            }
        }
    }

    .p-chkbox {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.25em;
        width: 1.25em;
        height: 1.25em;
        cursor: default;

        .p-chkbox-box {
            border: 2px solid $checkboxBorderColor;
            width: 1.25em;
            height: 1.25em;
            overflow: hidden;
            @include transition(background-color .3s);

            .p-chkbox-icon {
                font-size: 1.25em;
                margin-left: -2px;
                margin-top: -2px;
            }

            &.p-state-active {
                border-color: $primaryColor;
                background-color: $primaryColor;
            }

            &.p-focus {
                border-color: $primaryColor;
                @include content-shadow();
                @include transition(box-shadow .3s,background-color .3s);
            }
        }
    }

    .p-chkbox-label {
        margin-left: $inlineSpacing;
    }

    .p-radiobutton {
        position: relative;
        margin: 0 .25em 0 0;
        vertical-align: middle;

        .p-radiobutton-box {
            border: 2px solid $radioButtonBorderColor;
            width: 1.25em;
            height: 1.25em;
            @include transition(box-shadow .3s);
            @include border-radius(50%);

            &.p-focus {
                border-color: $primaryColor;
                @include content-shadow();
                @include transition(box-shadow .3s,background-color .3s);
            }

            &.p-state-active {
                border-color: $primaryColor;
                background-color: transparent;
            }

            .p-radiobutton-icon {
                display: block;
                box-sizing: border-box;
                @include border-radius(50%);
                font-size: 1em;
                transition: -webkit-transform ease .28s;
                transition: transform ease .28s;
                -webkit-transform: scale(0);
                transform: scale(0);
                margin: 0;
            }

            .pi-circle-on {
                &.pi:before {
                    content: "circle";
                }
                background-color: $primaryColor;
                -webkit-transform: scale(0.5);
                transform: scale(0.5);
                top: 50%;
                left: 50%;
                margin-top: -.5em;
                margin-left: -.5em;
            }
        }
    }

    .p-radiobutton-label {
        margin-left: $inlineSpacing;
    }

    .p-autocomplete-panel {
        @include border-radius(0);

        &.ui-shadow {
            @include overlay-input-shadow();
        }

        .p-autocomplete-list {
            padding: 0;

            .p-autocomplete-list-item {
                @include transition(background-color .3s);
                font-size: $inputOptionFontSize;
                padding: $inputOptionPadding;
                @include border-radius(0);
                color: $textColor;

                .p-autocomplete-query {
                    font-weight: 700;
                }

                &.p-highlight {
                    color: $accentTextColor;
                }
            }

            .p-autocomplete-group {
                padding: $inputOptionPadding;
            }
        }
    }

    .p-autocomplete {
        .p-autocomplete-dropdown {
            &.p-button.p-button-icon-only {
                background-color: transparent;
                @include no-shadow();
                height: 1.5em;
                width: 1.5em;
                padding: 0;
                right: 0;
                bottom: 0;
                margin-right: 0;
                position: absolute;

                .p-button-text {
                    display: none;
                }

                .pi {
                    color: $textSecondaryColor;
                }
            }
        }

        &.p-autocomplete-multiple {
            .p-autocomplete-multiple-container {
                &.p-inputtext {
                    box-sizing: border-box;
                    padding: 2px;
                }

                &.p-disabled {
                    border-bottom: 1px dotted;
                }
            }

            .p-autocomplete-input-token {
                float: none;
                display: inline-block;
                margin: 0 1px;
                vertical-align: middle;

                > input {
                    padding: 0;
                    font-size: $fontSize;
                    margin: 0;
                    vertical-align: bottom;
                }
            }

            .p-autocomplete-token {
                display: inline-block;
                float: none;
                vertical-align: middle;
                font-size: 1em;

                .p-autocomplete-token-label {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .p-autocomplete-dropdown {
                top: .75em;
            }
        }
    }

    .p-fluid {
        .p-autocomplete {
            &.p-autocomplete-dd {
                > .p-inputtext {
                    width: 100%;
                }
            }
        }
        .p-inputgroup{
            .md-inputfield {
                .p-autocomplete{
                    top: 5px;
                }
            }
        }
    }

    .p-dropdown {
        border-width: 0;
        background: transparent no-repeat;
        background-image: linear-gradient(to bottom, $primaryColor, $primaryColor), linear-gradient(to bottom, $inputBorderColor, $inputBorderColor);
        background-size: 0 2px, 100% 1px;
        background-position: 50% 100%, 50% 100%;
        transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
        padding-bottom: 2px;
        box-sizing: border-box;
        width: 100%;
        @include border-radius(0);

        .p-dropdown-trigger {
            height: 1.5em;
            width: 1.5em;
            margin-top: 0;
            padding: 0;
            top: 2px;
            margin-right: 0;

            .pi {
                color: $textSecondaryColor;
            }
        }

        .p-dropdown-label {
            background: none;
            &.p-inputtext {
                font: $inputFontSize "Roboto","Helvetica Neue",sans-serif;
            }
        }

        &:not(.p-disabled).p-focus {
            border-width: 0;
            background-size: 100% 2px, 100% 1px;
            padding-bottom: 2px;

            .p-dropdown-label {
                padding-bottom: 2px;
            }
        }
    }

    .p-dropdown-panel {
        @include border-radius(0);

        .p-dropdown-list {
            padding: 0;
        }

       .p-dropdown-items .p-dropdown-item.p-highlight {
            color: black;
            background: $accentColor;
        }
        .p-dropdown-item {
            margin: 0;
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            color: $textColor;
            @include transition(background-color .3s);
            @include border-radius(0);

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                @include hover-element();
            }
        }

        &.ui-shadow {
            @include overlay-input-shadow();
        }

        .p-dropdown-filter-container {
            display: block;
            border-bottom: 1px solid $inputBorderColor;
            background-color: $primaryColor;
            padding: $inputHeaderPadding;

            input {
                color: $inputListItemFilterTextColor;

                &:focus {
                    border-bottom-color: $inputListItemFilterTextColor;
                }
            }

            svg {
                color: white;
            }

            .pi {
                top: .625em;
                right: .75em;
                color: $inputListItemFilterTextColor;
            }
        }

        .p-dropdown-header {
            padding: 0;
            border-bottom: 1px solid rgba(0,0,0,.12);
            color: rgba(0,0,0,.87);
            background: #fff;
            margin: 0;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }
    }

    .p-multiselect {
        border-bottom: 1px solid $inputBorderColor;
        box-sizing: border-box;
        @include border-radius(0);
        @include transition(border-color .3s);

        .p-multiselect-label-container {
            padding-right: 1.5em;

            .p-multiselect-label {
                padding: 10px 2px 0px 2px;
                font-size: 13px;
                color: black;
            }
        }

        .p-multiselect-trigger {
            height: 1.5em;
            width: 1.5em;
            margin-top: 0;
            padding: 0;
            top: -2px;

            .chevrondownicon {
                color: $textSecondaryColor;
            }
        }

        &:not(.p-disabled).p-focus {
            border-bottom: 2px solid $primaryColor;
        }
    }

    .p-multiselect-panel.p-component {
        padding: 0;
        @include border-radius(0);
        @include overlay-input-shadow();
        min-width: 12.5em;

        .p-multiselect-header {
            @include border-radius(0);
            padding: $inputHeaderPadding;
            position: relative;
            background-color: $headerBgColor;
            
            .p-checkbox {
                float: none;
                margin: 0 .5em 0 -.125em;
                position: static;

                .p-checkbox-box {
                    border-color: $inputListItemCheckboxBorderColor;

                    .p-checkbox-icon {
                        border-color: $inputListItemFilterTextColor;
                    }

                    &.p-highlight {
                        .p-checkbox-icon {
                            border-color: $inputListItemFilterTextColor;
                        }
                    }

                    &.p-focus {
                        background-color: $primaryLightColor;
                        @include transition(background-color .3s);
                    }
                }
            }
            // .p-chkbox {
            //     float: none;
            //     margin: 0 .5em 0 -.125em;
            //     position: static;

            //     .p-chkbox-box {
            //         border-color: $inputListItemCheckboxBorderColor;

            //         .p-chkbox-icon {
            //             border-color: $inputListItemFilterTextColor;
            //         }

            //         &.p-state-active {
            //             .p-chkbox-icon {
            //                 border-color: $inputListItemFilterTextColor;
            //             }
            //         }

            //         &.p-focus {
            //             background-color: $primaryLightColor;
            //             @include transition(background-color .3s);
            //         }
            //     }
            // }

            .p-multiselect-filter-container {
                width: 70%;
                display: inline-block;
                vertical-align: middle;
                float: none;
                margin-left: 0;

                svg {
                    top: 10px;
                    left: 0;
                }

                .p-inputtext {
                    padding: 2px;
                    padding-left: 1.5em;
                    width: 100%;
                    box-sizing: border-box;
                    color: $inputListItemFilterTextColor;
                    border-bottom:2px solid $inputListItemFilterTextColor;
                    &:focus {
                        border-bottom-color: $inputListItemFilterTextColor;
                        border-top-color: $inputListItemFilterTextColor;
                        border-left-color: $inputListItemFilterTextColor;
                        border-right-color: $inputListItemFilterTextColor;
                    }
                }
                .p-multiselect-filter-icon {
                    right: .75rem;
                    color: $whiteButtonColor;
                }
                
                
            }

            .p-multiselect-close {
                position: absolute;
                right: .5em;
                top: .625em;
                height: 1.5em;
                width: 1.5em;

                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 1em;
                    height: 1em;
                    margin-top: -.5em;
                    margin-left: -.5em;
                    color: $inputListItemFilterTextColor;
                }

                &:hover {
                    background-color: $primaryLightColor;
                    @include border-radius(50%);
                    @include transition(background-color .3s);
                }
            }
        }

        .p-multiselect-item {
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            color: $textColor;
            @include border-radius(0);

            &:not(.p-highlight):not(.p-disabled):hover {
                @include hover-element();
            }

            &.p-highlight {
                color: $accentTextColor;
            }
        }
    }

    .p-spinner {
        .p-spinner-button {
            width: 1em;
            height: .75em;
            padding: 0;
            margin-right: .25em;
            background-color: transparent;
            color: $textColor;
            z-index: auto;
            @include no-shadow();

            .pi-triangle-1-n {
                color: $textColor;
            }

            .pi-triangle-1-s {
                color: $textColor;
            }

            .pi {
                top: 0px;
                height: .5em;
                color: $textSecondaryColor;
            }

            &:enabled:hover,
            &:active {
                background-color: transparent;
            }
        }

        .p-spinner-up {
            .pi {
                top: .25em;
            }
        }

        .p-spinner-down {
            .pi {
                top: .167em;
            }
        }

        .p-spinner-input {
            padding-right: 30px;
        }
    }

    .p-fluid {
        .p-spinner {
            .p-spinner-button {
                width: 1em;
                height: .75em;

                .pi {
                    left: auto;
                }
            }
        }
    }

    .p-inputswitch {
        height: 14px;
        width: 34px !important;

        .p-inputswitch-slider {
            background-color: rgb(158,158,158);
            @include border-radius(8px);
            @include transition(background-color .3s);

            &:before {
                background-color: $inputSwitchOffHandleBgColor;
                height: 1.429em;
                width: 1.429em;
                left: 0;
                bottom: -0.214em;
                border-radius: 50%;
                -webkit-transition: $transitionDuration;
                transition: $transitionDuration;
                -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
                -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
            }
        }

        &.p-inputswitch-checked {
            .p-inputswitch-slider:before {
                -webkit-transform: translateX(1.250em);
                -ms-transform: translateX(1.250em);
                transform: translateX(1.250em);
            }
        }

        &.p-inputswitch-focus {
            .p-inputswitch-slider {
                background-color: rgb(158,158,158);
            }
        }

        &:not(.p-disabled):hover {
            .p-inputswitch-slider {
                background-color: rgb(158,158,158);
            }
        }

        &.p-inputswitch-checked {
            .p-inputswitch-slider {
                background-color: $accentLightColor;

                &:before {
                    background-color: $accentColor
                }
            }

            &:not(.p-disabled):hover {
                .p-inputswitch-slider {
                    background-color: $accentLightColor;
                }
            }

            &.p-inputswitch-focus {
                .p-inputswitch-slider {
                    background: $accentLightColor;
                }
            }
        }
    }

    .p-slider {
        .p-slider-handle {
            background-color: $accentColor;
            color: $accentTextColor;
            @include border-radius(50%);
            width: 1.25em;
            height: 1.25em;
            transform: scale(.7);
            @include transition(all .4s cubic-bezier(.25,.8,.25,1));

            &.p-focus {
                transform: scale(1);
            }

            &:focus {
                outline: 0 none;
            }
        }

        &.p-slider-horizontal {
            height: 2px;
            border: 0 none;
            background-color: $inputBorderColor;

            .p-slider-handle {
                top: -.55em;
            }
        }

        &.p-slider-vertical {
            width: 2px;
            border: 0 none;
            background-color: $inputBorderColor;

            .p-slider-handle {
                left: -.55em;
            }
        }

        .p-slider-range {
            background-color: $accentColor;
            color: $accentTextColor;
        }

        &:not(.p-disabled) {
            .p-slider-handle {
                &:hover {
                    transform: scale(1);
                }
            }
        }
    }

    .p-calendar {
        .p-datepicker {
            min-width: auto;
        }
        .p-datepicker-trigger {
            bottom: 2px;
            right: 0;
            background-color: transparent;
            color: $textColor;
            height: 1.5em;
            width: 1.5em;
            @include no-shadow();
            @include border-radius(0);

            .pi {
                color: $textSecondaryColor;
            }

            &:enabled:hover {
                background-color: transparent;
            }
        }
    }

    .p-fluid {
        .p-calendar.p-calendar-w-btn {
            .p-inputtext {
                width: 100%;
            }
        }
    }

    .p-datepicker {
        padding: 0;
        width: 20em;

        &.ui-shadow {
            @include overlay-input-shadow();
        }

        &:not(.p-disabled) {
            .p-datepicker-header {
                .p-datepicker-prev,
                .p-datepicker-next {
                    &:hover {
                        background-color: $primaryLightColor;
                    }
                }
            }

            table {
                td {
                    a:not(.p-state-active):not(.p-highlight):hover {
                        @include hover-element();
                    }
                }
            }

            .p-monthpicker {
                a.p-monthpicker-month:not(.p-state-active):hover {
                    @include hover-element();
                }
            }
        }

        .p-datepicker-header {
            padding: $inputHeaderPadding;
            font-size: $inputHeaderFontSize;
            background: $primaryDarkColor;
            border-color: $primaryDarkColor;
            @include border-radius-top(2px);
            @include border-radius-bottom(0);

            .p-datepicker-prev {
                cursor: pointer;
                top: .4em;
                left: .2em;
                @include border-radius(50%);
                @include material-icon("keyboard_arrow_left");
                @include transition(background-color .3s);
                color: $calendarNavIconColor;

                .pi {
                    display: none;
                }
            }

            .p-datepicker-next {
                cursor: pointer;
                top: .4em;
                right: .2em;
                @include material-icon("keyboard_arrow_right");
                @include border-radius(50%);
                @include transition(background-color .3s);
                color: $calendarNavIconColor;

                .pi {
                    display: none;
                }
            }

            select.p-datepicker-month,
            select.p-datepicker-year {
                font-size: $fontSize - 2;
            }
        }

        table {
            table-layout: fixed;
            border-spacing: 0;
            border-collapse: collapse;
            font-size: $fontSize - 2;
        }

        thead {
            tr {
                color: $calendarCellTextColor;
                background: $primaryColor;
            }
        }

        tbody {
            td {
                padding: .25em .125em;
                box-sizing: border-box;

                &.p-datepicker-today {
                    a {
                        color: $calendarCellTextColor;
                        background: lighten($primaryColor,20%);

                        &.p-state-active {
                            background-color: $accentColor;
                            color: $accentTextColor;
                        }
                    }
                }

                a {
                    padding: .25em;
                    margin: 0;
                    text-align: center;
                    color: $textColor;
                    display: inline-block;
                    height: 2.250em;
                    width: 2.250em;
                    @include border-radius(50%);
                    line-height: 1.8em;

                    &.p-state-active {
                        background-color: $accentColor;
                        color: $accentTextColor;
                    }

                    span {
                        display: inline;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }

        .p-timepicker {
            @include border-radius(0);

            > div {
                a {
                    height: 1.5em;

                    > span {
                        display: inline-block;
                        margin-bottom: .25em;
                    }

                    .pi {
                        &.pi-angle-up {
                            @include rotate(0);
                            @include material-icon("keyboard_arrow_up");
                        }

                        &.pi-angle-down {
                            @include rotate(0);
                            @include material-icon("keyboard_arrow_down");
                        }
                    }
                }
            }
        }

        .p-monthpicker {
            .p-monthpicker-month {
                color: $textColor;

                &.p-state-active {
                    color: $accentTextColor;
                    background-color: $accentColor;
                }
            }
        }

        &.p-datepicker-multiple-month {
            width: auto;
            .p-datepicker-group {
                border-right: 1px solid $dividerColor;
                padding-top: 0;
                padding-bottom: 0;

                .p-datepicker-calendar-container {
                    tbody {
                        padding-right: .25em;
                        padding-left: .25em;
                    }
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    border-right: 0 none;
                }
            }
        }
    }

    #ui-datepicker-div {
        @include overlay-input-shadow();
    }

    .p-rating {
        font-size: inherit;
        min-height: 1.5em;

        .pi-ban {
            @include material-icon("cancel");
            color: $textSecondaryColor;
        }

        .pi-star {
            @include material-icon("star");
            color: $textSecondaryColor;
        }

        .pi-star-o {
            @include material-icon("star_border");
            color: $textSecondaryColor;
        }
    }

    .p-chips {
        > ul.p-inputtext {

            &.p-disabled {
                border-bottom: 1px dotted;
            }

            .p-chips-input-token {
                padding-bottom: 2px;
                margin-bottom: 2px;

                > input {
                    padding: 0;
                    font-size: $fontSize;
                    margin: 0;
                    vertical-align: bottom;
                }

                input:disabled {
                    width: 0;
                }
            }

            .p-chips-token {
                display: inline-block;
                float: none;
                vertical-align: middle;
                font-size: 1em;
                margin-bottom: 2px;

                .p-chips-token-label {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    .p-password-panel {
        &.ui-shadow {
            @include overlay-input-shadow();
        }
    }

    .p-fileupload {
        .p-fileupload-buttonbar {
            padding: $headerPadding;

            .pi-arrowreturnthick-1-n {
                @include icon_override('file_upload');
            }

            .p-button {
                background: $accentColor;

                &:enabled:hover {
                    background-color: $accentDarkColor;
                }
                &:disabled {
                background-color: darken($accentColor, 5%) !important;
                color: $whiteButtonColor !important;
                }
            }
        }

        .p-fileupload-content {
            padding: 0.5rem;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            .p-fileupload-files .p-fileupload-row{
                display: table-row;
                > div {
                    width: auto;
                    flex: 0.2 1 auto;
                    display: table-cell;
                    padding: 0.5em 1em;
                    vertical-align: middle;
                }
            }
            .p-messages-error {
                .pi {
                    color: $errorMessageTextColor;
                }
            }
        }
    }

    .p-fileupload-choose:not(.p-disabled):hover,
    .p-fileupload-choose.p-focus {
        &.p-button {
            background-color: darken($accentColor, 5%);
        }
    }

    .p-fileupload-choose:not(.p-disabled):active {
        &.p-button {
            background-color: darken($accentColor, 10%);
        }
    }

    .p-editor-container {
        .p-editor-toolbar {
            background-color: $editorHeaderBgColor;
        }
    }

    /* InputGroup */
    .p-inputgroup {
        .p-inputgroup-addon {
            &.p-inputgroup-addon-fill {
                background-color: $inputFieldFillBgColor;
            }
            border-width: 0 0 1px 0;
            border-color: $inputBorderColor;
            color: $textSecondaryColor;
            padding: 2px 2px 1px 2px;
            align-self: flex-end;

            > i {
                @include flex();
                align-self: flex-end;
            }
        }

        .p-inputtext {
            align-self: flex-end;
        }

        .md-inputfield {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            display: inherit;

            label {
                top: 5px;
            }
            .p-inputwrapper-filled ~ label {
                top:-20px;
            }
            .p-inputwrapper-focus ~ label {
                top:-20px;
            }

            .p-message {
                top: 100%;
            }
        }

        .p-chkbox, .p-radiobutton {
            margin-bottom: .125em;
        }
    }

    .p-selectbutton {
        .p-button {
            &:not(.p-state-active):not(.p-disabled):hover {
                background-color: $primaryDarkColor;
            }

            &.p-state-active {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }
    }

    .p-fluid {
        .p-inputgroup {
            .p-button-icon-only {
                width: 1.643em;
                height: 1.643em;
                padding: 0;

                .p-button-icon-left {
                    margin-left: -.45em;
                }
            }
        }
    }

    /* Validation */
    .p-inputtext.ng-dirty.ng-invalid,
    p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
    p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
    p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
    p-chips.ng-dirty.ng-invalid > .p-inputtext,
    p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
    p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
    p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
    p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
    p-listbox.ng-dirty.ng-invalid .p-inputtext,
    p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
    p-spinner.ng-dirty.ng-invalid > .p-inputtext,
    p-selectbutton.ng-dirty.ng-invalid .p-button,
    p-togglebutton.ng-dirty.ng-invalid .p-button {
        background-image: linear-gradient(to bottom, $inputBorderErrorColor, $inputBorderErrorColor), linear-gradient(to bottom, $inputBorderErrorColor, $inputBorderErrorColor);
    }
}

@media (max-width: 640px) {
    body {
        .p-buttonset {
            > .p-button {
                display: block;
                @include border-radius(0);

                &:first-child {
                    @include border-radius-top($borderRadius);
                }

                &:last-child {
                    @include border-radius-bottom($borderRadius);
                }
            }
        }
        .p-splitbutton {
            > .p-button {
                display: inline-block;

                &:first-child {
                    @include border-radius-top(0);
                }

                &:last-child {
                    @include border-radius-bottom(0);
                }
            }
        }
    }
}
