body {
    .p-picklist {
        .p-picklist-caption {
            font-size: $inputHeaderFontSize;
            padding: $inputHeaderPadding;
        }

        .p-picklist-list {
            padding: 0;
        }

        .p-picklist-filter-container {
            .pi {
              left: 0.3em;
            }

            .p-picklist-filter {
                text-indent: 1.5em;
            }
        }

        li.p-picklist-item {
            font-size: $inputOptionFontSize;
            padding: $inputOptionPadding;
            margin: 0;
            @include border-radius(0);
            color: $textColor;

            &:not(.p-highlight):hover {
                @include hover-element();
            }

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }

        .p-picklist-buttons {
            width: 3em;

            .p-button {
                &.p-button-icon-only {
                    width: 2.5em;
                    margin-right: 0;
                    display: inline-block;
                    margin-bottom: .5em;
                }
            }
        }

        .p-picklist-buttons-cell {
            text-align: center;
        }

        &.p-picklist-responsive {
            .p-picklist-buttons {
                .p-button {
                    &.p-button-icon-only {
                        margin: 0 auto;
                        display: block;
                        margin-bottom: .5em;
                    }
                }
            }

            .p-picklist-list {
                .p-picklist-item {
                    .p-chkbox {
                        margin-right: .5em;
                        vertical-align: top;
                    }

                    .p-chkbox,.p-chkbox * {
                        box-sizing: content-box;
                    }
                }
            }
        }
    }

    .p-organizationchart {
        .p-organizationchart-node-content {
            &.p-organizationchart-selectable-node:not(.p-highlight):hover {
                @include hover-element();
            }

            &.p-highlight {
                .p-node-toggler {
                    i {
                        color: darken($accentColor, 25%);
                    }
                }
            }
        }
        .p-organizationchart-line-down {
            background-color: $dividerColor;
        }

        .p-organizationchart-line-left {
            border-right: 1px solid $dividerColor;
        }

        .p-organizationchart-line-right {

        }

        .p-organizationchart-line-top {
            border-top: 1px solid $dividerColor;
        }

        .p-organizationchart-node-content {
            border-color: $dividerColor;
        }

        .p-organizationchart-node-content .p-node-toggler {
            bottom: -1.3em;
            margin-left: -.75em;
            color: $dividerColor;

            .pi-chevron-down {
                @include material-icon("keyboard_arrow_down");
            }

            .pi-chevron-up {
                @include material-icon("keyboard_arrow_up");
            }
        }
    }

    .p-orderlist {
        .p-orderlist-caption {
            font-size: $inputHeaderFontSize;
            padding: $inputHeaderPadding;
            box-sizing: border-box;
        }

        .p-orderlist-list {
            padding: 0;
            box-sizing: border-box;

            li.p-orderlist-item {
                font-size: $inputOptionFontSize;
                padding: $inputOptionPadding;
                margin: 0;
                @include border-radius(0);
                color: $textColor;

                &:not(.p-highlight):hover {
                    @include hover-element();
                }

                &.p-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;
                }
            }
        }

        &.p-orderlist-responsive {
            .p-orderlist-controls {
                width: 3.75em;
            }
        }

        .p-orderlist-controls {
            width: 3.75em;
            text-align: center;

            .p-button {
                &.p-button-icon-only {
                    width: 2.5em;
                    margin-bottom: .5em;
                    margin-right: 0;
                }
            }
        }
    }

    .p-paginator {
        background-color: $primaryDarkColor;
        padding: $headerPadding;

        > a {
            box-sizing: border-box;
            color: $paginatorIconColor;

            .pi {
                display: none;
            }

            &:not(.p-disabled):not(.p-state-active):hover {
                background-color: $primaryLightColor;
                @include border-radius(50%);
                @include transition(background-color .3s);
            }
        }
        > button {
            box-sizing: border-box;
            color: #919191;

            .pi {
                display: none;
            }

            &:not(.p-disabled):not(.p-state-active):hover {
                background-color: $primaryLightColor;
                @include border-radius(50%);
                @include transition(background-color .3s);
            }
        }

        .p-paginator-next {
            padding: 0;
            vertical-align: middle;
            @include material-icon("navigate_next");
            min-width: 1rem;
        }

        .p-paginator-last {
            padding: 0;
            vertical-align: middle;
            @include material-icon("last_page");
            min-width: 1rem;
        }

        .p-paginator-prev {
            padding: 0;
            vertical-align: middle;
            @include material-icon("navigate_before");
            min-width: 1rem;
        }

        .p-paginator-first {
            padding: 0;
            vertical-align: middle;
            @include material-icon("first_page");
            min-width: 1rem;
        }

        .p-paginator-pages {
            vertical-align: middle;
            margin: 0 .375em 0 .375em;
            min-width: 1.5em;
            min-height: 1.5em;
            line-height: 1.5em;
            a {
                padding: 0;
                color: $paginatorIconColor;
                min-width: 1.5em;
                min-height: 1.5em;
                line-height: 1.5em;
                @include border-radius(50%);
                @include transition(background-color .3s);

                &:not(.p-state-active):hover {
                    background-color: $primaryLightColor;
                }

                &.p-state-active {
                    background-color: $accentColor;
                    color: $accentTextColor;
                }
            }
            .p-paginator-page {
                padding: 5px;
                min-width: 0;
                min-height: 0;
                line-height: 0.7em;
                height: auto;
                width: 20px;
                font-size: 13px;
                color: black;
                @include border-radius(50%);
                @include transition(background-color .3s);

                &:not(.p-highlight):hover {
                    background-color: $primaryLightColor;
                }

                &.p-highlight {
                    background-color:#919191;;
                    color: #fff;
                }
            }
        }

        .p-dropdown {
            background-image: linear-gradient(to bottom, $headerBgColor, $headerBgColor), linear-gradient(to bottom, $headerTextColor, $headerTextColor);
            .p-dropdown-trigger {
                .pi{
                    color: $headerTextColor;
                }
            }

            .p-dropdown-label {
                background: none;
                padding-right: 1.5em;

            }
        }
    }

    .p-datagrid {
        .p-datagrid-header {
            padding: $headerPadding;
        }

        .p-panel {
            .p-panel-titlebar {
                background-color: $tableHeaderCellBgColor;
                color: $tableHeaderCellTextColor;
                border-color: $dividerColor;
            }
        }
    }

    .p-datalist {
        .p-datalist-header {
            padding: $headerPadding;
        }
    }

    .p-datatable table{
        .p-datatable-caption,
        .p-datatable-summary {
          padding: $headerPadding;
          text-align: center;
          font-weight: normal;
        }

        .p-datatable-caption {
          border-bottom: 0 none;
        }

        .p-datatable-summary {
          border-top: 0 none;
        }

        .p-datatable-thead > tr > th,
        .p-datatable-tbody > tr > td,
        .p-datatable-tfoot > tr > td {
            padding: $contentPadding;
        }

        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
            font-weight: 400;
            color: $tableHeaderCellTextColor;
            background-color: $tableHeaderCellBgColor;
            border: 1px solid $dividerLightColor;
        }

        .p-datatable-thead tr .p-inputtext {
             width: 100%;
        }

        .p-datatable-scrollable-header {
            border: none;

            .p-sortable-column {
                .p-sortable-column-icon {
                    color: $textColor;
                }

                &.p-highlight {
                    .p-sortable-column-icon {
                        color: $accentTextColor;
                    }
                }
            }
        }

        .p-datatable-loading-content {
            .pi-spin {
                -webkit-animation: 2s linear infinite fa-spin;
                animation: 2s linear infinite fa-spin;
                color: $tableContentLoadIconColor;
            }
        }

        .p-sortable-column {
            color: $tableHeaderCellTextColor;

            a, a:hover {
                color: $textSecondaryColor;
            }

            &:not(.p-highlight):hover {
                @include hover-element();

                a, a:hover {
                    @include hover-element();
                }
            }

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
                border-top-color: $accentColor;

                a, a:hover {
                    color: $accentTextColor;
                }
            }

            .p-sortable-column-icon {
                vertical-align: middle;
            }
        }

        .p-datatable-tbody {
            > tr {
                background: $tableBodyRowBgColor;

                > td {
                  background: inherit;
                  border: 1px solid $dividerLightColor;
                }

                &.p-highlight {
                  background-color: $accentColor;
                  color: $accentTextColor;
                }

                &.p-contextmenu-selected {
                  background-color: $accentColor;
                  color: $accentTextColor;
                }
            }

            > tr:nth-child(odd) {
                background-color: $tableOddRowBgColor;

                &.p-highlight {
                  background-color: $accentColor;
                  color: $accentTextColor;
                }

                &.p-contextmenu-selected {
                  background-color: $accentColor;
                  color: $accentTextColor;
                }
            }
        }

        &.p-datatable-hoverable-rows {
            .p-datatable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
              cursor: pointer;
              @include hover-element();
            }
        }

        .p-datatable-reorder-indicator-down, .p-datatable-reorder-indicator-up {
            position: absolute;
            display: none;
        }
    }

    @media ( max-width: 35em ) {
        .p-datatable-responsive .p-datatable-tbody > tr {
            border-top: 1px solid $dividerLightColor;
            border-bottom: 1px solid $dividerLightColor;

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
            }
        }
    }

    @media screen and (max-width: 40em) {
        .p-datatable-responsive .p-datatable-tbody > tr > td {
            border: 0 none;
        }
    }

    .p-datatable {
        .p-datatable-header{
            padding: $headerPadding;
            border:none;
            .p-inputtext {
                color: $datatableHeaderCellBgColor;
        
                &:focus {
                    border-color: $datatableHeaderCellBgColor;
                }
            }
        }
        .p-datatable-footer {
            padding: 0;
            border:none;
            background: #607d8b;
            font-weight: 400;
            text-align: center;
            color: white;
            .p-inputtext {
                color: $datatableHeaderCellBgColor;
        
                &:focus {
                    border-color: $datatableHeaderCellBgColor;
                }
            }
        }

        .p-paginator {
            background: white;
            padding: $headerPadding;
        }

        .p-paginator-top {
            padding: 0.25em 1em;
            background: none;
        }

        .p-datatable-thead {
            > tr {
                 border-color: $dividerLightColor;

                 th {
                    padding: .625em .875em;
                    background-color: $datatableHeaderCellBgColor;

                    &.p-sortable-column:not(.p-state-active):hover {
                        @include hover-element();
                    }

                    .p-column-title {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .p-sortable-column-icon {
                        vertical-align: middle;
                        color: $textSecondaryColor;
                    }

                    &.p-highlight {
                        background-color: $accentColor;
                        color: $accentTextColor;
                        border-top-color: $accentColor;

                        .pi {
                            color: $accentTextColor;
                        }

                        .p-inputtext {
                            color: $accentTextColor;

                            &.p-focus {
                                border-color: $accentTextColor;
                            }
                        }
                    }

                    &.p-selection-column {
                        .p-chkbox-box {
                            box-sizing: content-box;
                        }
                    }
                }
            }
        }

        tfoot {
            th {
                padding: .625em .875em;
                border: 1px solid $dividerColor;
                background-color: $datatableHeaderCellBgColor;
            }
        }

        .p-datatable-data {
            tr.p-datatable-even {
                background-color: $datatableEvenRowBgColor;

                &.p-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;
                }
            }

            tr {
                border: 1px solid $dividerLightColor;

                td {
                    padding: .625em .875em;

                    .p-row-toggler  {
                        display: inherit;
                    }

                    &.p-highlight {
                        .p-inputtext {
                            color: $datatableHighlightTextColor;
                            border-color: $datatableHighlightTextColor;

                            &:focus {
                                border-color: $datatableHighlightTextColor;
                            }
                        }
                    }

                    &.p-state-error {
                        background-color: $errorMessageBgColor;
                        border-color: $errorMessageBorderColor;
                        color: $errorMessageTextColor;
                    }

                    input.p-cell-editor {
                        padding: 2px 2px 1px 2px;
                        font-size: $inputFontSize;
                    }

                    &.p-selection-column {
                        .p-chkbox-box, .p-radiobutton-box {
                            box-sizing: content-box;
                        }
                    }
                }

                &.p-highlight{
                    background-color: $accentColor;
                    border-color: $accentColor;
                    color: $accentTextColor;
                }

                .p-cell-editor-input {
                    input {
                        color: $accentTextColor;
                    }
                }
            }

            tr.p-state-error {
                background-color: $errorMessageBgColor;
                border-color: $errorMessageBorderColor;
                color: $errorMessageTextColor;

                .p-inputtext,
                .p-inputtext.p-state-error {
                    border-color: $errorMessageTextColor;
                }
            }

            tr.p-highlight {
                td.p-selection-column {
                    .p-radiobutton-box {
                        border-color: $datatableHighlightTextColor;

                        .p-radiobutton-icon {
                            background-color: $datatableHighlightTextColor;
                        }
                    }

                    .p-chkbox-box {
                        border-color: $datatableHighlightTextColor;
                        background-color: $datatableHighlightTextColor;

                        .p-chkbox-icon {
                            color: $textSecondaryColor;
                        }
                    }
                }

                .p-inputtext {
                    color: $datatableHighlightTextColor;
                    border-color: $datatableHighlightTextColor;

                    &:focus {
                        border-color: $datatableHighlightTextColor;
                    }
                }
            }

            tr.p-rowgroup-header {
                td {
                    a {
                        height: 1.5em;
                        vertical-align: top;
                        display: inline-block;
                    }
                }
            }

            &.p-datatable-hoverable-rows {
                > tr.p-component-content:not(.p-highlight):hover {
                    cursor: pointer;
                    @include hover-element();
                }
            }
        }

        &.p-datatable-scrollable {
            .p-datatable-scrollable-header,  .p-datatable-scrollable-footer {
                border: 0 none;
                background-color: transparent;

                .p-datatable-data {
                    td {
                        color: $textColor;
                    }
                }
            }

            thead {
                tr {
                    th {
                        color: $textColor;
                        font-size: $fontSize;
                    }
                }
            }

            tfoot {
                tr {
                    td {
                        color: $textColor;
                        font-size: $fontSize;
                    }
                }
            }
        }

        .p-datatable-loading {
            @include opacity(.3);
        }

        .p-datatable-loading-content {
            top: 35%;

            .pi {
                @include material-icon('refresh');
                @include animation(0, 1000ms, spin);
                font-size: 2em;
            }
        }
    }

    .p-dataview {
        .p-dataview-header {
            background-color: $dataviewHeaderBgColor;
            color: $textColor;
            padding: $headerPadding;
            border: 1px solid $dividerLightColor;
            border-bottom: 0 none;

            .p-button {
                &.p-state-active {
                    &:hover {
                        background-color: $accentColor;
                        color: $accentTextColor;
                    }
                }
            }
        }

        .p-dataview-content {
            background-color: $contentBgColor;
            color: $textColor;
            border: 1px solid $dividerLightColor;
        }

        .p-dataview-footer {
            background-color: $dataviewHeaderBgColor;
            color: $textColor;
            padding: $headerPadding;
            border: 1px solid $dividerLightColor;
            border-top: 0 none;
        }
    }

    .p-carousel {
        padding: 0;

        .p-carousel-header{
            padding: $headerPadding;
            font-size: $headerFontSize;
            overflow: visible;

            .p-carousel-header-title {
                overflow: visible;
            }

            .pi {
                color: $panelHeaderIconColor;
            }

            .p-carousel-button {
                margin: -1px 0 0 0;
            }

            .p-carousel-page-links {
                margin: -1px 0 0 0;
            }
        }
    }

    .p-tree {
        padding: $contentPadding / 2;

        .p-treenode-children {
            padding-left: 1.75em;
        }

        .p-treenode-content {

            .p-chkbox {
                margin-right: .5em;

                .pi {
                    color: $contentIconColor;
                }
            }

            .p-tree-toggler {
                vertical-align: middle;
                margin: 0 0 0 .25em;
            }

            .p-treenode-icon  {
                vertical-align: middle;
                margin: 0 .25em;
            }

            .p-chkbox {
                margin: 0 .25em;
            }

            .p-treenode-label {
                margin: 0;
                vertical-align: middle;
            }

            &.p-treenode-selectable {
                &:not(.p-highlight):hover {
                    @include hover-element();
                }
            }
        }

        &.p-tree-horizontal {
            padding-left: 0;
            padding-right: 0;

            .p-treenode-content {
                background-color: $contentBgColor;
                border: 1px solid $dividerColor;

                .p-tree-toggler {
                    vertical-align: bottom;
                }

                .p-treenode-icon  {
                    vertical-align: top;
                    margin-right: .25em;
                }

                &.p-treenode-selectable {
                    &:not(.p-highlight):hover {
                        @include hover-element();
                    }
                }

                &.p-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;
                }
            }
        }
    }

    .p-tree-draghelper {
        border: 1px solid $primaryColor;
    }

    .fc {
        .fc-toolbar {
            .p-icon-circle-triangle-e {
                @include material-icon("play_circle_outline");
                margin-top: .15em;
            }

            .p-icon-circle-triangle-w {
                @include material-icon("play_circle_outline"); @include rotate(180deg);
                margin-top: .15em;
            }

            .p-state-active {
                background-color: $accentColor;
            }
        }

        .fc-event {
            background-color: $primaryLightColor;
            color: $scheduleEventTextColor;
        }

        table {
            box-sizing: border-box;
        }
    }

    .p-treetable {
        .p-treetable-caption,
        .p-treetable-summary {
            padding: $headerPadding;
            text-align: center;
            font-weight: normal;
        }

        .p-treetable-caption {
            border-bottom: 0 none;
        }

        .p-treetable-summary {
            border-top: 0 none;
        }

        .p-treetable-thead > tr > th,
        .p-treetable-tbody > tr > td,
        .p-treetable-tfoot > tr > td {
            padding: $contentPadding;
        }

        .p-treetable-thead > tr > th,
        .p-treetable-tfoot > tr > td {
            font-weight: 400;
            color: $tableHeaderCellTextColor;
            background-color: $tableHeaderCellBgColor;
            border: 1px solid $dividerLightColor;
        }

        .p-treetable-thead tr .p-inputtext {
            width: 100%;
        }

        .p-treetable-scrollable-header {
            border: none;
        }

        .p-treetable-loading-content {
            .pi-spin {
                -webkit-animation: 2s linear infinite fa-spin;
                animation: 2s linear infinite fa-spin;
                color: $tableContentLoadIconColor;
            }
        }

        .p-sortable-column {
            color: $tableHeaderCellTextColor;

            a, a:hover {
                color: $textSecondaryColor;
            }

            &:not(.p-highlight):hover {
                @include hover-element();

                a, a:hover {
                    @include hover-element();
                }
            }

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
                border-top-color: $accentColor;

                a, a:hover {
                    color: $accentTextColor;
                }
            }

            .p-sortable-column-icon {
                vertical-align: middle;
            }
        }

        .p-treetable-tbody {
            > tr {
                background: $tableBodyRowBgColor;

                > td {
                    background: inherit;
                    border: 1px solid $dividerLightColor;

                    .p-treetable-toggler {
                        color: $textSecondaryColor;
                        .pi.pi-chevron-right {
                            vertical-align: top;
                        }
                        .pi.pi-chevron-down {
                            vertical-align: middle;
                        }
                    }

                    .p-treetable-chkbox {
                        vertical-align: middle;
                        margin-right: .4em;
                    }
                }

                &.p-highlight {
                    background-color: $accentColor;
                    color: $accentTextColor;

                    > td {
                        background: inherit;
                        border: $dividerColor;

                        .p-treetable-toggler {
                            color: $accentTextColor;
                        }
                    }
                }

                &.p-contextmenu-selected {
                    background-color: $accentColor;
                    color: $accentTextColor;

                    > td {
                        background: inherit;
                        border: $dividerColor;

                        .p-treetable-toggler {
                            color: $accentTextColor;
                        }
                    }
                }
            }
        }

        &.p-treetable-hoverable-rows {
            .p-treetable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
                cursor: pointer;
                @include hover-element();
            }
        }
    }

}

@media (max-width: 40em) {
    body {
        .p-orderlist {
            &.p-orderlist-responsive {
                .p-orderlist-controls {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    body {
        .p-picklist {
            &.p-picklist-responsive {
                .p-picklist-list-wrapper {
                    margin-bottom: .5em;
                }

                .p-picklist-buttons {
                    padding: .5em 0;

                    .p-button {
                        &.p-button-icon-only {
                            display: inline-block;
                            margin-right: .25em;
                            margin-bottom: 0;
                        }
                    }

                    .pi-angle-right {
                        @include icon-override("play_arrow"); @include rotate(90deg);
                    }

                    .pi-angle-double-right {
                        @include icon-override("skip_previous"); @include rotate(-90deg);

                    }

                    .pi-angle-left {
                        @include icon-override("play_arrow"); @include rotate(-90deg);
                    }

                    .pi-angle-double-left {
                        @include icon-override("skip_next"); @include rotate(-90deg);
                    }
                }
            }
        }

        .p-orderlist {
            &.p-grid-responsive {
                .p-orderlist-controls {
                    text-align: center;
                    width: auto;

                    .p-button {
                        margin-right: .25em;
                    }
                }
            }
        }
    }
}
