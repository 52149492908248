/* You can add global styles to this file, and also import other style files */

@import "./app/sass/_vars";
@import "./app/sass/common";
@import "./app/sass/forms";
@import "./app/sass/menu";
@import "./app/sass/modal";
@import "./app/sass/material";
.scroll-content {
    overflow: visible;
}

body .p-menu.p-component {
    width: inherit;
}

.scroll-content.scroll-content-sidenav-filter {
    height: 100%;
}

.scroll-content.scroll-content-sidenav-filter-visible {
    margin-left: $sidenav-filter-width;
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.panel-width-fix.p-dropdown-panel {
    max-width: 200px;
}

// Can hopefully be removed when https://github.com/angular/material2/issues/3927 is fixed
.mat-mdc-tooltip {
    font-size: 14px !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */

mat-card {
    // margin-bottom: 60px;
    margin: 25px 25px 60px;
    padding: 24px !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */

mat-card-title {
    margin-bottom: 16px !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */

// Keep the commented code to revert if broken some place it works on modal popup on scrollbar fix
// mat-dialog-container ::-webkit-scrollbar {
//     background: none;
// }


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */

mat-dialog-container ::-webkit-scrollbar-track-piece {
    margin: 4px;
}


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */

mat-dialog-container ::-webkit-scrollbar-thumb {
    border: solid 0 transparent;
    border-right-width: 4px;
    border-left-width: 4px;
    -webkit-border-radius: 9px 4px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(128, 128, 128, 0.2), inset 0 0 0 4px rgba(128, 128, 128, 0.2);
}


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */

mat-dialog-container ::-webkit-scrollbar-thumb:horizontal {
    border-right-width: 0;
    border-left-width: 0;
    border-top-width: 4px;
    border-bottom-width: 4px;
    -webkit-border-radius: 4px 9px;
}


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */


/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */

mat-dialog-container ::-webkit-scrollbar-thumb:hover {
    -webkit-box-shadow: inset 0 0 0 1px rgba(128, 128, 128, 0.9), inset 0 0 0 4px rgba(128, 128, 128, 0.9);
}

.scannerOverlay {
    max-width: 100vw !important;
}

.scannerOverlay .mat-dialog-container {
    padding-left: 5px;
    padding-right: 5px;
}

.page-title {
    overflow: hidden;
    text-overflow: ellipsis;
    &-container {
        white-space: nowrap;
        overflow: hidden;
    }
}

.uppercase {
    text-transform: uppercase;
}

textarea.mat-mdc-input-element {
    resize: none;
}

ngx-gallery ::ng-deep ngx-gallery-image .ngx-gallery-arrow {
    color: $primaryDarkColor !important;
    font-size: 40px !important;
}

// Icon Ligatures for ngx-gallery
.material-icons.auto_renew:before {
    content: "\e863";
}

.material-icons.close:before {
    content: "\e5cd";
}

.material-icons.delete:before {
    content: "\e872";
}

.material-icons.file_download:before {
    content: "\e2c4";
}

.material-icons.keyboard_arrow_left:before {
    content: "\e314";
}

.material-icons.keyboard_arrow_right:before {
    content: "\e315";
}

.material-icons.rotate_right:before {
    content: "\e41a";
}

.material-icons.star:before {
    content: "\e838";
    color: #f4c84b;
}

.material-icons.star_border:before {
    content: "\e83a";
}

.material-icons.zoom_in:before {
    content: "\e8ff";
}

.material-icons.zoom_out:before {
    content: "\e900";
}

.material-icons.star {
    opacity: 1 !important;
}

@media only screen and (max-width: 600px) {
    .mat-mdc-card,
    .p-datatable {
        margin: 0 !important;
        box-shadow: none !important;
    }
    button {
        box-shadow: none !important;
    }
}

button.mat-mdc-raised-button {
    border-radius: 2px;
}

button.mat-mdc-raised-button:disabled {
    color: rgba(0, 0, 0, .26);
}

// For the sidenav
.filters-selected {
    a,
    span {
        color: $accentColor !important;
    }
}

.p-datepicker td>span,
.p-datepicker td>a {
    cursor: pointer;
    user-select: none;
    padding: 0.2rem !important;
}

.p-datepicker-touch-ui.p-datepicker th {
    padding: 0.2rem !important;
}

.p-datepicker-touch-ui {
    min-width: 40vw !important;
}

.p-paginator-top .p-paginator-right-content {
    visibility: hidden !important;
}

.p-paginator-top .p-inputwrapper-filled {
    visibility: hidden !important;
}

.delete-text-color {
    color: #f44336;
}

.remove-text-color {
    color: #f4c84b;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.stretch {
    align-items: stretch;
}

.gap-0 {
    gap: 0;
}

.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px !important;
}

.gap-40 {
    gap: 40px !important;
}

.space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-flex-start {
    justify-content: flex-start;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.center {
    align-items: center;
}

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    place-content: center space-between;
}

.tab-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.tab-container-details {
    display: flex;
    flex-direction: column;
    @media (min-width: 600px) {
        flex-direction: row;
    }
    justify-content: space-around;
    align-items: stretch;
}

.justify-flex-end {
    justify-content: flex-end !important;
}

.justify-space-around {
    justify-content: space-around !important;
}

.form-container {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    @media screen and (min-width: 600px) {
        flex-direction: column;
    }
}

//fxFlex
.default-flex {
    flex: 1 1 0%;
    box-sizing: border-box;
}

.flex-auto {
    flex: auto;
}

.flex-none {
    flex: none !important;
}

.flex-1 {
    flex: 1 !important;
}

.flex-10 {
    flex: 1 1 100%;
    box-sizing: border-box !important;
    max-width: 10% !important;
}

.flex-15 {
    flex: 0 0 15% !important;
    box-sizing: border-box !important;
    max-width: 15% !important;
}

.flex-20 {
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 20% !important;
}

.flex-18 {
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 18% !important;
}

.flex-25 {
    flex: 0 0 100% !important;
    box-sizing: border-box !important;
    max-width: 25% !important;
}

.flex-30 {
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 30% !important;
}

.flex-35 {
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 35% !important;
}

.flex-40 {
    flex: 1 1 100% !important;
    box-sizing: border-box !important;
    max-width: 40% !important;
}

.flex-45 {
    flex: 0 0 45% !important;
}

.flex-50 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 50%;
}

.flex-55 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 55%;
}

.flex-60 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 60%;
}

.flex-70 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 70%;
}

.flex-80 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 80%;
}

.flex-100 {
    flex: 1 1 100%;
    max-width: 100%;
}

.display-none {
    display: none !important;
}

.display-block {
    display: block !important;
}

.ml-25 {
    margin-left: 25% !important;
}

.mr-25 {
    margin-right: 25% !important;
}

//fxLayout=row
.layout-row {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

//fxLayout=column
.layout-column {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
}

//fxlayout=column fxLayoutAlign=space-around stretch
.layout-column-space-around-stretch {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: stretch space-around;
    align-items: stretch;
    max-width: 100%;
}

//fxFlex= 20%
.layout-flex-20 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-height: 20%;
}

//fxFlex=45%
.layout-flex-45 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 45%;
}

//fxlayout=row fxLayoutAlign=space-around stretch
.layout-row-space-around-stretch {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch space-around;
    align-items: stretch;
    max-height: 100%;
}

//fxLayout="row" fxLayoutAlign="space-between"
.layout-row-space-between {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch space-between;
    align-items: stretch;
}

//fxLayout="row" fxLayoutAlign="space-between stretch"
.flex-layout-row-space-between-stretch {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch space-between;
    align-items: stretch;
    max-height: 100%;
}

//fxLayout="column" fxLayoutAlign="start stretch"
.layout-column-start-stretch {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: stretch flex-start;
    align-items: stretch;
    max-width: 100%;
}

//fxLayout="row" fxLayoutAlign="end none"
.layout-row-end-none {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch flex-end;
    align-items: stretch;
}

//fxLayout="row" fxLayoutAlign="center center"
.layout-row-center {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
}

//fxLayout="column" fxLayoutAlign="center center"
.layout-column-center {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
}
//fxLayout="row" fxLayoutAlign="start start"
.flex-layout-row-align-start-start {
    margin-bottom: 40px;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 1e-09px;
    place-content: flex-start;
    align-items: flex-start;
}

//fxLayout="column" fxLayoutAlign="start start"
.flex-layout-column-align-start-start {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: flex-start;
    align-items: flex-start;
}

//fxFill
.layout-fill {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.fs-16 {
    font-size: 16px;
}

.cdk-virtual-scroll-spacer {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
}

.pt-24 {
    padding-top: 24px !important;
}

//fxLayoutAlign="end end"
.layout-align-end {
    place-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

//fxLayout="row" fxLayoutAlign="space-between center"
.layout-row-align-space-between-center {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;
}

//fxLayout="row" fxLayoutAlign="start stretch" 
.layout-row-align-start-stretch {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch flex-start;
    align-items: stretch;
    max-height: 100%;
}

