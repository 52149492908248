body {
    .p-breadcrumb {
        padding: $inputHeaderPadding;

        ul {
            li {
                a {
                    color: $panelHeaderTextColor;
                    font-size: $inputHeaderFontSize;
                }
            }
        }
    }

    .p-steps {
        position: relative;

        .p-steps-item {
            background-color: transparent;

            &.p-disabled {
                @include opacity(1);
            }

            .p-menuitem-link {
                display: inline-block;
                text-align: left;
                background-color: $stepsItemBgColor;
                overflow: hidden;

                .p-steps-number {
                    display: inline-block;
                    background-color: $grayBgColor;
                    @include border-radius(50%);
                    padding: .25em .75em;
                    font-size: 1em;
                    color: $stepsItemNumberColor;
                }

                .p-steps-title {
                    display: inline;
                    margin-left: .625em;
                    color: $stepsItemTextColor;
                }
            }

            &.p-highlight {
                .p-steps-number {
                    background-color: $accentColor;
                }

                .p-steps-title {
                    font-weight: 700;
                    color: $textColor;
                }
            }

            &:last-child {
                .p-menuitem-link {
                    display: block;
                }
            }
        }

        &:before {
            content:' ';
            border: 1px solid $dividerColor;
            width: 90%;
            top: 45%;
            left: 0;
            display: block;
            position: absolute;
        }
    }

    .p-menu .p-menuitem-link,
    .p-menubar .p-menuitem-link,
    .p-tieredmenu .p-menuitem-link,
    .p-contextmenu .p-menuitem-link,
    .p-megamenu .p-menuitem-link,
    .p-slidemenu .p-menuitem-link {
        padding: .625em 1em;
        color: $textColor;
        @include border-radius(0);

        &:hover {
            @include hover-element();
        }

        &:focus {
            @include hover-element();
        }
        .p-menuitem-text {
          vertical-align: middle;
        }

        .p-menuitem-icon {
            color: $menuitemIconColor;
        }
    }

    .p-menubar, .p-contextmenu,
    .p-slidemenu, .p-tieredmenu {
        .p-submenu-list {
            padding: .5em 0;

            .p-menuitem {
              margin: 0;
            }
        }
    }

    .p-menu {
        min-width: 12.5em;
        padding: .5em 0;

        .p-menuitem {
          margin: 0;

          .p-menuitem-link {
            .p-menuitem-icon {
              vertical-align: middle;
            }
          }
        }

        &.ui-shadow, .ui-shadow {
            @include overlay-input-shadow();
        }

        .p-submenu-header {
            border: 0 none;
            margin: 0;
            padding: .625em 1em;
            border-radius: 0;
        }
    }

    .p-menubar {
        padding: 0;

        .p-menubar-root-list {
          &>.p-menuitem>.p-menuitem-link {
            padding: .625em 1em;
          }
        }

        .p-menubar-root-list>.p-menuitem>.p-menuitem-content>.p-menuitem-link {
            .p-menuitem-text {
                color: white;
            }
            .p-submenu-icon {
                color: white;
            }
        }
        .p-menuitem {
            .p-menuitem-link {
                .p-menuitem-icon {
                    vertical-align: middle;
                }

                .p-submenu-icon {
                  vertical-align: middle;
                }
            }
            &.p-menuitem-active {
                > .p-menuitem-link {
                  @include hover-element;

                  .p-menuitem-icon {
                    vertical-align: middle;
                  }
                }
            }
        }
    }

    .p-contextmenu {
        padding: .5em 0;

        .p-menuitem {

            .p-menuitem-link {
              .p-menuitem-icon {
                vertical-align: middle;
              }
            }
            &.p-menuitem-active {
                > .p-menuitem-link {
                  @include hover-element;
                }
            }
        }
    }

    .p-slidemenu {
        padding: .5em 0;

        .p-menuitem {
            margin: 0;

            .p-menuitem-link {
              .p-menuitem-icon {
                vertical-align: middle;
              }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                  @include hover-element;
                }
            }
        }

        .p-slidemenu-backward {
            margin-bottom: .4em;
            padding: $headerPadding;
            width: 100%;
            background-color: $primaryColor;
            box-sizing: border-box;
            @include border-radius(0);
            border: 0 none;

            &:hover {
              background-color: darken($primaryColor, 10%);
            }
        }
    }

    .p-tieredmenu {
        padding: .5em 0;

        .p-menuitem {

            .p-menuitem-link {
              .p-menuitem-icon {
                vertical-align: middle;
              }
            }
            &.p-menuitem-active {
              > .p-menuitem-link {
                @include hover-element;
              }
            }
        }
    }

    .p-tabmenu {
         padding: 0;

         .p-tabmenu-nav {
             padding: 0;
             background-color: $tabsNavBgColor;
             border: 0 none;
             @include border-radius(0);

             > .p-tabmenuitem {
               top: auto;
               margin: 0 .25em 0 0;
               padding: 0;
               border-style: solid;
               border-width: 0 0 2px 0;
               @include transition(border-color .3s);

               > a {
                   padding: $headerPadding;

                   &:focus {
                       outline: 0 none;
                   }

                   .p-menuitem-icon, .p-menuitem-text {
                       float: none;
                       display: inline-block;
                       vertical-align: middle;
                   }

                   .p-menuitem-icon {
                       font-weight: 1em;
                       margin-right: .25em;
                   }
               }

               &.p-state-default {
                   a {
                       color: $textSecondaryColor;

                       .p-menuitem-icon {
                           color: $textSecondaryColor;
                       }
                   }
               }

               &:not(.p-state-active):hover {
                   background-color: $tabsNavBgColor;
               }

               &.p-state-active {
                   background-color: $tabsNavBgColor;
                   border-color: $accentColor;
                   border-style: solid;

                   a {
                       color: $primaryColor;

                       .p-menuitem-icon {
                           color: $primaryColor;
                       }
                   }
               }
           }
        }
    }

    .p-megamenu {
        padding: 0;

        .p-megamenu-submenu-header {
          border-radius: 0;
          padding: .5em;
        }

        .p-submenu-list {
            padding: 0;
            border: 0 none;

            .p-menuitem {
              margin: 0;
            }
        }

        .p-menuitem {
          margin: 0;

          .p-menuitem-link {
            .p-menuitem-icon {
              vertical-align: middle;
            }

            .p-submenu-icon {
              vertical-align: middle;
            }
          }

          &.p-menuitem-active {
            > .p-menuitem-link {
              @include hover-element;
            }
          }
        }
    }

    .p-panelmenu {
        .p-panelmenu-header {
            background-color: $primaryColor;
            margin-bottom: 1px;

            a {
                padding: .625em 1em;
                color: $panelHeaderTextColor;
                font-size: $fontSize;
                border-bottom: 1px solid $panelHeaderTextColor;
            }

            .pi {
                color: $panelHeaderIconColor;
                vertical-align: middle;
            }

            .p-menuitem-icon {
                color: $panelHeaderIconColor;
                vertical-align: middle;
            }

            .p-menuitem-text {
                vertical-align: middle;
            }

            &.p-state-active {
                background-color: $accentColor;
                @include border-radius-bottom(0);
                margin-bottom: 0;
                a, .p-menuitem-icon {
                    color: $accentTextColor;
                }
            }
        }

        .p-panelmenu-content {
            padding: 0;

            .p-menuitem {
                margin: 0;

                .p-menuitem-link {
                    border: 0 none;
                    padding: .625em 1em;;
                    width: 100%;
                    min-height: 2.750em;
                    box-sizing: border-box;
                    color: $textColor;
                    line-height: 1.5em;
                    @include border-radius(0);
                    position: relative;

                    &:hover {
                        @include hover-element();
                    }

                    .p-menuitem-text {
                        display: inline-block;
                        vertical-align: middle;
                        float: none;
                    }

                    .pi {
                        position: static;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .p-menuitem-icon {
                        vertical-align: middle;
                    }
                }
            }
        }

        .p-menu-parent .p-menu-list {
            margin-left: 28px;
        }
    }
}

@media (max-width: 640px) {
    body {
        .p-steps {
            .p-steps-item {
                .p-menuitem-link {
                    .p-steps-title {
                        display: none;
                    }
                }
            }
        }
    }
}
