@import '../../sass/vars.scss';

.p-column-filter {
    display: inline-flex;
    width: 100%;
    box-sizing: border-box;
    margin-top: .25em;
    border: 1px solid $dividerColor;
    height: 43px;
    font-weight: lighter;

    .p-multiselect-label-container label {
        padding-left: 4px;
    }
}

input.p-column-filter {
    padding: 5px;
    font-weight: lighter;
}

.column-filter-slider {
    width: 100%;
    margin-top: 8px;
}

input.p-column-filter.active-filter, .p-column-filter.active-filter {
    font-weight: bold;
    border: 2px solid #ff5722;
}

.multiselect-filter {
    margin-top: .25em;
    border: 1px solid #BDBDBD;
    font-weight: lighter;
    font-size: 1em;
    width: 100% !important;
    height: 26px;
    padding-top: 3px;
}

.active-multiselect-filter {
    border: 2px solid #ff5722 !important;
    label {
        font-weight: bold !important;
    }
}

