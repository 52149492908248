@import "../variables";

// General
$iconFontSize:1.5em;
$inlineSpacing:.5em;

// Common
$headerFontSize:1em;
$headerPadding:.625em 1em;
$headerBgColor:$primaryColor;
$headerTextColor:#ffffff;
$headerIconColor:#ffffff;
$contentFontSize:1em;
$contentPadding:.625em 1em;
$contentBgColor:#ffffff;
$contentIconColor:#757575;
$overlayBgColor:#58575c;

// InputText
$inputHeaderFontSize:1em;
$inputHeaderPadding:.625em 1em;
$inputFontSize:1em;
$inputOptionFontSize:1em;
$inputBgColor: transparent;
$inputBorderColor:#bdbdbd;
$inputBorderErrorColor:#e62a10;
$inputErrorTextColor:#e62a10;
$inputFieldLabelTextColor:#999999;
$inputFieldBoxBgColor:#f7f7f7;
$inputFieldFillBgColor:#f7f7f7;
$inputAutoFillBorderColor: #bdbdbd;

// Button
$buttonFontSize:1em;
$buttonTextColor:#ffffff;

// Input lists
$inputListItemBgColor:#ffffff;
$inputListItemCheckboxBorderColor:#ffffff;
$inputListItemFilterTextColor:#ffffff;

//input switch
$inputSwitchOffHandleBgColor:#ffffff;

//checkbox
$checkboxBorderColor:#757575;

//radiobutton
$radioButtonBorderColor:#757575;

//calendar
$calendarNavIconColor:#ffffff;
$calendarCellTextColor:#ffffff;

//messages, growl, toast
$messageIconColor:#ffffff;
$infoMessageBgColor:#2196F3;
$infoMessageBorderColor:#2196F3;
$infoMessageTextColor:#ffffff;

$successMessageBgColor:#8BC34A;
$successMessageBorderColor:#8BC34A;
$successMessageTextColor:#ffffff;

$warnMessageBgColor:#ffc107;
$warnMessageBorderColor:#ffc107;
$warnMessageTextColor:#ffffff;

$errorMessageBgColor:#e62a10;
$errorMessageBorderColor:#e62a10;
$errorMessageTextColor:#ffffff;

//editor
$editorHeaderBgColor:#e8e8e8;

//paginator
$paginatorIconColor:#ffffff;

//turbo table
$dividerLightColor:#cacaca;
$tableOddRowBgColor:#f4f4f4;
$tableHeaderCellBgColor:#ffffff;
$tableHeaderCellTextColor:$textColor;
$tableContentLoadIconColor:#ffffff;
$tableBodyRowBgColor:#ffffff;

//datatable
$datatableEvenRowBgColor:#f4f4f4;
$datatableHeaderCellBgColor:#ffffff;
$datatableHighlightTextColor:#ffffff;

//dataview
$dataviewHeaderBgColor:#ffffff;

//schedule
$scheduleEventBgColor:$primaryLightColor;
$scheduleEventTextColor:#ffffff;

//steps
$stepsItemBgColor:#ffffff;
$stepsItemNumberColor:#ffffff;
$stepsItemTextColor:$textSecondaryColor;
$menuitemIconColor:#757575;

//tabview
$tabsNavBgColor:#ffffff;

//panel common
$panelHeaderBgColor:$primaryColor;
$panelHeaderBorderColor:$primaryColor;
$panelHeaderTextColor:#ffffff;
$panelHeaderIconColor:#ffffff;

//dialog
$dialogHeaderBgColor:#ffffff;
$dialogHeaderTextColor:$textColor;
$dialogFooterButtonBgColor:#ffffff;
$dialogFooterButtonTextColor:$textColor;

//tooltip
$tooltipBgColor:#323232;
