body {
    .p-panel {
        padding: 0;

        .p-panel-titlebar {
            border: 0 none;
            border-bottom: 1px solid $panelHeaderBorderColor;
            padding: $headerPadding;
            @include border-radius-top($borderRadius - 1);
            @include border-radius-bottom(0);

            .p-panel-title {
                line-height: 1.5em;
            }

            .p-panel-titlebar-icon {
                position: relative;
                width: 1.5em;
                height: 1.5em;
                color: $panelHeaderIconColor;
                margin: 0;
                @include transition(background-color .3s);
                @include border-radius(50%);

                &:hover {
                    background-color: $primaryLightColor;
                }
            }
        }

        .p-panel-content {
            height: 100%;
            box-sizing: border-box;
            padding: $contentPadding;
        }

        .p-panel-footer {
            padding: $headerPadding;
            border: 0 none;
            border-top: 1px solid $dividerColor;
            margin: 0;
        }
    }

    .p-fieldset {
        padding: $contentPadding;

        .p-fieldset-legend {
            padding: $headerPadding;
            padding-left: .125em;
            padding-right: .5em;
            color: $primaryColor;

            .p-fieldset-toggler {
                padding: .5em;
                height: 2em;
                width: 2em;
                background-color: $panelHeaderBgColor;
                color: $panelHeaderIconColor;
                font-size: 1.5em;
                @include border-radius(50%);
                @include transition(background-color .3s);
                margin-right: .5em;
                @include content-shadow();

                &:hover {
                    background-color: $primaryDarkColor;
                }
            }

            .p-fieldset-legend-text {
                color: $primaryColor;
            }

            &.p-focus {
                background-color: transparent;
            }
        }
    }

    .p-accordion {
        .p-accordion-header {
            background-color: $panelHeaderBgColor;
            padding: 0;
            color:$panelHeaderTextColor;
            font-size: $headerFontSize;
            @include transition(background-color .3s);

            > a {
                padding: $headerPadding;
                color: $panelHeaderTextColor;

                .p-accordion-toggle-icon,
                .p-accordion-header-text {
                    vertical-align: middle;
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                background-color: $primaryDarkColor;
            }

            &.p-highlight {
                background-color: $accentColor;
                color: $accentTextColor;
                @include border-radius-bottom(0);

                > a {
                    color: $accentTextColor;
                }
            }
        }

        .p-accordion-content {
            padding: $contentPadding;
            line-height: $lineHeight;
        }
    }

    .p-toolbar {
        background-color: $primaryDarkColor;
        @include content-shadow();
        padding: .625em;
    }

    .p-tabview {
        padding: 0;

        .p-tabview-nav {
            background-color: $tabsNavBgColor;
            border: 0 none;
            @include border-radius(0);

            > li {
                padding: 0;
                @include transition(border-color .3s);

                > a {
                    padding: $headerPadding;

                    &:focus {
                        outline: 0 none;
                    }
                }

                > .pi-times {
                    margin: .325em 0 0 0;
                    @include transition(color .3s);
                    color: $textSecondaryColor;
                }

                .p-tabview-left-icon, .p-tabview-right-icon {
                    color: $textSecondaryColor;
                    display: inline-block;
                    vertical-align: middle;
                }

                .p-tabview-title {
                    line-height: 1.5em;
                    vertical-align: middle;
                }

                &.p-state-default {
                    a {
                        color: $textSecondaryColor;
                    }
                }

                &:not(.p-highlight):not(.p-disabled):hover {
                    background-color: $tabsNavBgColor;
                }

                &.p-highlight {
                    background-color: $tabsNavBgColor;
                    border-color: $accentColor;
                    border-style: solid;

                    a {
                        color: $primaryColor;
                    }

                    > .pi-times {
                        color: $accentColor;
                    }
                }

                &.p-tabview-outline {
                    outline: 0 none;
                    border-color: $accentLightColor;
                }
            }
        }

        .p-tabview-panel {
            padding: $contentPadding;
        }

        &.p-tabview-top {
            > .p-tabview-nav {
                padding: 0;
                margin: 0;
                @include border-radius-top(4px);
                border-bottom: 1px solid $dividerColor;

                > li {
                    border-style: solid;
                    border-width: 0 0 2px 0;
                }
            }
        }

        &.p-tabview-bottom {
            > .p-tabview-nav {
                padding: 0;
                margin: 0;
                @include border-radius-bottom(4px);
                border-top: 1px solid $dividerColor;

                > li {
                    border-width: 2px 0 0 0;
                }
            }
        }

        &.p-tabview-left {
            > .p-tabview-nav {
                padding: 0;
                margin: 0;
                @include border-radius-left($borderRadius + 1);
                border-right: 1px solid $dividerColor;

                > li {
                    box-sizing: border-box;
                    border-width: 0 2px 0 0;

                    > a {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        &.p-tabview-right {
            > .p-tabview-nav {
                padding: 0;
                @include border-radius-right($borderRadius + 1);
                border-left: 1px solid $dividerColor;

                > li {
                    box-sizing: border-box;
                    border-width: 0 0 0 2px;

                    > a {
                        width: 100%;
                        box-sizing: border-box;
                    }

                    &.p-state-active {
                        > a {
                            padding-left: 0.875em;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    body {
        .p-toolbar {
            .p-toolbar-group-right {
                margin-top: 1em;
            }
        }
    }
}
